import React, {Component} from "react";
import VerDetallesPresentacionUI from "./VerDetallesPresentacionUI";
import PageError from "../componentes/PageError";
import PageLoading from "../componentes/PageLoading";
import swal from "sweetalert";
import jsPDF from "jspdf";
import {PRECIO_COSTO, IGV} from "../Global";
import Notify from "./Notify";

class VerDetallesPresentacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            presentacion: {
                Nombre: "",
                Descripcion: "",
                IdLote: null,
                IdUnidad: null,
                Medida: null,
                IdPresentacion: null,
                CodigoBarras: null
            },
            precios: [],
            lotes: [],
            unidades: [],
            preciosinactivos: [],
            monedas: [],
            isLoading: false,
            error: null,
            respuestaUpdatePresentacion: null,
            respuestaUpdatePrecio: null,
            respuestaNewPrecio: null,
            respuestaDeletePrecio: null,
            mensajedeactualizacionprecio: null,
            mensajedenewprecio: null,
            mensajedeactualizacion: null,
            precioCosto: [],
            IdPrecio: null,
            precio: {
                IdPrecio: null,
                IdPrecioPlantilla: null,
                NombrePrecio: null,
                IdMoneda: null,
                Precio: null,
                Porcentaje: null,
                Observaciones: null,
                Cantidad: null
            },
            NuevoNombrePrecio: null,
            NuevoPrecio: null,
            NuevasObservaciones: null,
            NuevoPorcentaje: null,
            NuevaIdMoneda: 1,
            NuevaCantidad: 0,
            PrecioIGV: null,
            NuevoPrecioIGV: null,
            AfectacionProducto: null
        };

        this.handleNombreChange = this.handleNombreChange.bind(this);
        this.handleDescripcionChange = this.handleDescripcionChange.bind(this);
        this.handleTipoCambioChange = this.handleTipoCambioChange.bind(this);
        this.handleUnidadChange = this.handleUnidadChange.bind(this);
        this.handleLoteChange = this.handleLoteChange.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleNuevoNombrePrecioChange = this.handleNuevoNombrePrecioChange.bind(
            this
        );
        this.handleNuevoPrecioChange = this.handleNuevoPrecioChange.bind(this);
        this.handleNuevasObservacionesChange = this.handleNuevasObservacionesChange.bind(
            this
        );
        this.handleNombrePrecioChange = this.handleNombrePrecioChange.bind(this);
        this.handlePrecioChange = this.handlePrecioChange.bind(this);
        this.handleObservacionesChange = this.handleObservacionesChange.bind(this);
        this.handleSubmitPrecio = this.handleSubmitPrecio.bind(this);
        this.handleSubmitNewPrecio = this.handleSubmitNewPrecio.bind(this);
        this.handleSubmitActualizarPresentacion = this.handleSubmitActualizarPresentacion.bind(
            this
        );
        this.handleOnDeletePrecio = this.handleOnDeletePrecio.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.handleMonedaChange = this.handleMonedaChange.bind(this);
        this.handleNuevaMonedaChange = this.handleNuevaMonedaChange.bind(this);
        this.handlePorcentajeChange = this.handlePorcentajeChange.bind(this);
        this.handleNuevoPorcentajeChange = this.handleNuevoPorcentajeChange.bind(
            this
        );
        this.handleCantidadChange = this.handleCantidadChange.bind(this);
        this.handleNuevaCantidadChange = this.handleNuevaCantidadChange.bind(this);
        this.handleNuevoPrecioIGVChange = this.handleNuevoPrecioIGVChange.bind(
            this
        );
        this.handlePrecioIGVChange = this.handlePrecioIGVChange.bind(this);
        this.handleCodigoBarras = this.handleCodigoBarras.bind(this);
        this.handleEsFacturableChange = this.handleEsFacturableChange.bind(this);
    }

    componentDidMount() {
        const IdPresentacion = this.props.IdPresentacion;
        const IdProducto = this.props.IdProducto;
        this.getPresentacion(IdPresentacion);
        this.getPrecios(IdPresentacion);
        this.getUnidades();
        this.getPreciosInactivos(IdPresentacion);
        this.getLotes();
        this.getMonedas();
        this.getAfectacionProducto(IdProducto);
        this.setState({isLoading: true});
    }

    handleNuevoNombrePrecioChange(e) {
        this.setState({
            NuevoNombrePrecio: e.target.value
        });
    }

    handleNuevaCantidadChange(e) {
        this.setState({NuevaCantidad: e.target.value});
    }

    async handleNuevoPrecioChange(e) {
        const NuevoPrecio = e.target.value;
        this.setState({
            NuevoPrecio: NuevoPrecio
        });

        if (this.state.precioCosto.Precio === 0) {
            swal("ERROR", "Precio de Costo está en 0", "error");
        } else {
            const CalculoNuevoPrecioIGV =
                Number(NuevoPrecio) + Number(NuevoPrecio) * IGV;

            await this.setState({
                NuevoPorcentaje:
                    ((NuevoPrecio - this.state.precioCosto.Precio) /
                        this.state.precioCosto.Precio) *
                    100,
                NuevoPrecioIGV: this.decimalAdjust("round", CalculoNuevoPrecioIGV, -6)
            });
            await this.setState(
                // (state,props)=>{}
                {
                    NuevoPorcentaje: this.decimalAdjust(
                        "round",
                        this.state.NuevoPorcentaje,
                        0
                    )
                }
            );
        }
    }

    async handleNuevoPorcentajeChange(e) {
        await this.setState({
            NuevoPorcentaje: e.target.value
        });

        if (this.state.precioCosto.Precio === 0) {
            swal("ERROR", "Precio de Costo está en 0", "error");
        } else {
            await this.setState({
                NuevoPrecio:
                    (this.state.NuevoPorcentaje * this.state.precioCosto.Precio) / 100 +
                    this.state.precioCosto.Precio
            });
            await this.setState({
                NuevoPrecio: this.decimalAdjust("round", this.state.NuevoPrecio, -2)
            });

            await this.setState({
                NuevoPrecioIGV: ((this.state.NuevoPorcentaje * this.state.precioCosto.Precio) / 100 +
                    this.state.precioCosto.Precio) * (1 + IGV)
            })
            await this.setState({
                NuevoPrecioIGV: this.decimalAdjust("round", this.state.NuevoPrecioIGV, -2)
            })

        }
    }

    handleNuevaMonedaChange(e) {
        this.setState({NuevaIdMoneda: e.target.value});
    }

    handleNuevasObservacionesChange(e) {
        this.setState({NuevasObservaciones: e.target.value.toUpperCase()});
    }

    handleNombrePrecioChange(e, precioModificado) {
        e.preventDefault();
        // console.log(e.target, " - ", precioModificado)
        // let precio = { ...this.state.precio };
        this.state.precios.map(precio => {
            if (precioModificado.IdPrecio == precio.IdPrecio) {
                precio.Nombre = e.target.value.toUpperCase();
            }
        })
        // precio.NombrePrecio = e.target.value.toUpperCase();
        // this.setState({ precio: precio });
    }

    handleCantidadChange(e, precioModificado) {
        // console.log(this.state)
        e.preventDefault();
        // let precio = { ...this.state.precio };
        this.state.precios.map(precio => {
            if (precioModificado.IdPrecio == precio.IdPrecio) {
                precio.Cantidad = e.target.value;
            }
        })
        // precio.Cantidad = e.target.value;
        // this.setState({ precio: precio });
    }

    async handlePrecioChange(e, precioModificado) {
        e.preventDefault();
        // let precio = { ...this.state.precio };
        this.state.precios.map(precio => {
            if (precioModificado.IdPrecio == precio.IdPrecio) {
                precio.Precio = e.target.value;
                const CalculoPrecioIGV =
                    Number(precio.Precio) + Number(precio.Precio) * IGV;
                precio.PrecioIGV = CalculoPrecioIGV
                if (
                    this.state.precioCosto.Precio === 0 &&
                    precio.IdPrecioPlantilla !== PRECIO_COSTO
                ) {
                    swal("ERROR", "Precio de Costo está en 0", "error");
                } else {
                    if (
                        precio.IdPrecioPlantilla !== PRECIO_COSTO ||
                        precio.IdPrecioPlantilla === null
                    ) {
                        precio.Porcentaje =
                            ((precio.Precio - this.state.precioCosto.Precio) /
                                this.state.precioCosto.Precio) *
                            100;
                        precio.Porcentaje = this.decimalAdjust(
                            "round",
                            precio.Porcentaje,
                            0
                        );

                    }
                }


            }
        })
        this.setState(
            {...this.state}
        );
        // precio.Precio = e.target.value;
        // await this.setState({ precio: precio });
        // const CalculoPrecioIGV =
        //   Number(precio.Precio) + Number(precio.Precio) * IGV;
        // await this.setState({
        //   PrecioIGV: this.decimalAdjust("round", CalculoPrecioIGV, -6)
        // });

        // if (
        //   this.state.precioCosto.Precio === 0 &&
        //   this.state.precio.IdPrecioPlantilla !== PRECIO_COSTO
        // ) {
        //   swal("ERROR", "Precio de Costo está en 0", "error");
        // } else {
        //   if (
        //     precio.IdPrecioPlantilla !== PRECIO_COSTO ||
        //     precio.IdPrecioPlantilla === null
        //   ) {
        //     precio.Porcentaje =
        //       ((precio.Precio - this.state.precioCosto.Precio) /
        //         this.state.precioCosto.Precio) *
        //       100;
        //     precio.Porcentaje = this.decimalAdjust(
        //       "round",
        //       this.state.precio.Porcentaje,
        //       0
        //     );
        //      this.setState({
        //       precio: precio
        //     });
        //   }
        // }
    }

    async handlePrecioIGVChange(e, precioModificado) {
        e.preventDefault();
        // let precio = { ...this.state.precio };
        this.state.precios.map(precio => {
            if (precioModificado.IdPrecio == precio.IdPrecio) {
                precio.PrecioIGV = e.target.value;

                if (
                    this.state.precioCosto.Precio === 0 &&
                    precio.IdPrecioPlantilla != PRECIO_COSTO
                ) {
                    swal("ERROR", "Precio de Costo está en 0", "error");
                } else {
                    precio.Precio = Number(e.target.value) / (1 + IGV);

                    precio.Porcentaje =
                        ((precio.Precio - this.state.precioCosto.Precio) /
                            this.state.precioCosto.Precio) *
                        100;
                    precio.Porcentaje = this.decimalAdjust(
                        "round",
                        precio.Porcentaje,
                        0
                    )
                    // this.setState({ precio: precio });
                }
            }
        })
        this.setState(
            {...this.state}
        );
        // const PrecioIGV = e.target.value;
        //  this.setState({ PrecioIGV: PrecioIGV });

        // if (
        //   this.state.precioCosto.Precio === 0 &&
        //   this.state.precio.IdPrecioPlantilla != PRECIO_COSTO
        // ) {
        //   swal("ERROR", "Precio de Costo está en 0", "error");
        // } else {

        //   precio.Precio = Number(PrecioIGV) / (1 + IGV);
        //   precio.Precio = this.decimalAdjust("round", precio.Precio, -6);

        //   precio.Porcentaje =
        //     (((PrecioIGV - IGV) - this.state.precioCosto.Precio) /
        //       this.state.precioCosto.Precio) *
        //     100;
        //     precio.Porcentaje = this.decimalAdjust(
        //       "round",
        //       precio.Porcentaje,
        //       0
        //     )
        //   this.setState({ precio: precio });
        // }
    }

    async handleNuevoPrecioIGVChange(e) {
        const NuevoPrecioIGV = e.target.value;
        await this.setState({NuevoPrecioIGV: NuevoPrecioIGV});

        if (
            this.state.precioCosto.Precio === 0 &&
            this.state.precio.IdPrecioPlantilla != PRECIO_COSTO
        ) {
            swal("ERROR", "Precio de Costo está en 0", "error");
        } else {
            let CalculoNuevoPrecio = Number(NuevoPrecioIGV) / (1 + IGV);
            CalculoNuevoPrecio = this.decimalAdjust("round", CalculoNuevoPrecio, -6);

            await this.setState({
                NuevoPorcentaje:
                    ((NuevoPrecioIGV - IGV - this.state.precioCosto.Precio) /
                        this.state.precioCosto.Precio) *
                    100
            });
            await this.setState(
                // (state,props)=>{}
                {
                    NuevoPorcentaje: this.decimalAdjust(
                        "round",
                        this.state.NuevoPorcentaje,
                        0
                    )
                }
            );

            await this.setState({NuevoPrecio: CalculoNuevoPrecio});
        }
    }

    async handlePorcentajeChange(e, precioModificado) {
        e.preventDefault();
        // let precio = { ...this.state.precio };
        this.state.precios.map(precio => {
            if (precioModificado.IdPrecio == precio.IdPrecio) {
                precio.Porcentaje = e.target.value.toUpperCase();

                if (this.state.precioCosto.Precio === 0) {
                    swal("ERROR", "Precio de Costo está en 0", "error");
                } else {
                    if (
                        precio.IdPrecioPlantilla != PRECIO_COSTO ||
                        precio.IdPrecioPlantilla === null
                    ) {
                        precio.Precio =
                            (precio.Porcentaje * this.state.precioCosto.Precio) / 100 +
                            this.state.precioCosto.Precio;

                        // await this.setState({
                        precio.PrecioIGV = ((precio.Porcentaje * this.state.precioCosto.Precio) / 100 +
                            this.state.precioCosto.Precio) * (1 + IGV)
                        // })
                        // await this.setState({
                        // })
                        // await this.setState({ precio: precio });
                    }
                }


            }
        })
        this.setState(
            {...this.state}
        );
        // precio.Porcentaje = e.target.value.toUpperCase();
        // this.setState({ precio: precio });

        // if (this.state.precioCosto.Precio === 0) {
        //   swal("ERROR", "Precio de Costo está en 0", "error");
        // } else {
        //   if (
        //     precio.IdPrecioPlantilla != PRECIO_COSTO ||
        //     precio.IdPrecioPlantilla === null
        //   ) {
        //     precio.Precio =
        //       (precio.Porcentaje * this.state.precioCosto.Precio) / 100 +
        //       this.state.precioCosto.Precio;
        //     precio.Precio = this.decimalAdjust("round", precio.Precio, -2);

        //     await this.setState({
        //       PrecioIGV: ((precio.Porcentaje * this.state.precioCosto.Precio) / 100 +
        //       this.state.precioCosto.Precio) * (1 + IGV)
        //     })
        //     await this.setState({
        //       PrecioIGV: this.decimalAdjust("round", this.state.PrecioIGV, -2)
        //     })

        //     await this.setState({ precio: precio });
        //   }
        // }
    }

    decimalAdjust(type, value, exp) {
        // Si el exp no está definido o es cero...
        if (typeof exp === "undefined" || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split("e");
        value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
        // Shift back
        value = value.toString().split("e");
        return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
    }

    handleObservacionesChange(e, precioModificado) {
        e.preventDefault();
        // let precio = { ...this.state.precio };
        this.state.precios.map(precio => {
            if (precioModificado.IdPrecio == precio.IdPrecio) {
                precio.Observaciones = e.target.value;
            }
        })
        // precio.Observaciones = e.target.value;
        // this.setState({ precio: precio });
    }

    handleNombreChange(e) {
        let presentacion = {...this.state.presentacion};
        presentacion.Nombre = e.target.value;
        this.setState({presentacion: presentacion});
    }

    handleEsFacturableChange(e) {
        let presentacion = {...this.state.presentacion};
        presentacion.EsFacturable = e.target.checked;
        this.setState({presentacion: presentacion});
    }

    handleCodigoBarras(e) {
        let presentacion = {...this.state.presentacion};
        presentacion.CodigoBarras = e.target.value;
        this.setState({presentacion: presentacion});
    }

    handleDescripcionChange(e) {
        let presentacion = {...this.state.presentacion};
        presentacion.Descripcion = e.target.value;
        this.setState({presentacion: presentacion});
    }

    handleLoteChange(e) {
        let presentacion = {...this.state.presentacion};
        presentacion.IdLote = e.target.value;
        this.setState({presentacion: presentacion});
    }

    handleUnidadChange(e) {
        let presentacion = {...this.state.presentacion};
        presentacion.IdUnidad = e.target.value;
        this.setState({presentacion: presentacion});
    }

    handleMonedaChange(e) {
        let precio = {...this.state.precio};
        precio.IdMoneda = e.target.value.toUpperCase();
        this.setState({precio: precio});
    }

    handleTipoCambioChange(e) {
        let presentacion = {...this.state.presentacion};
        presentacion.Medida = e.target.value.toUpperCase();
        this.setState({presentacion: presentacion});
    }

    getPresentacion(IdPresentacion) {
        fetch(`/api/presentaciones/get/${IdPresentacion}`)
            .then(response => response.json())
            .then(
                presentacion =>
                    this.setState({
                        presentacion: presentacion.respuesta[0],
                        isLoading: false
                    }),
                this.getPrecioCosto(IdPresentacion)
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    handleOnClick(IdPrecio, IdPresentacion) {
        return async () => {
            await Promise.all([
                this.getPrecio(IdPrecio),
                this.getPrecioCosto(IdPresentacion)
            ]);
            const PrecioIGV =
                this.state.precio.Precio + this.state.precio.Precio * IGV;
            this.setState({
                PrecioIGV: PrecioIGV,
                IdPrecio: IdPrecio
            });
        };
    }

    async getPrecio(IdPrecio) {
        await fetch(`/api/presentaciones/precios/get/${IdPrecio}`)
            .then(response => response.json())
            .then(precio => {
                    this.setState({
                        precio: precio.respuesta[0],
                        isLoading: false
                    })
                }
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getPrecioCosto(IdPresentacion) {
        await fetch(`/api/presentaciones/precios/getcosto/${IdPresentacion}`)
            .then(response => response.json())
            .then(precioCosto =>
                this.setState({
                    precioCosto: precioCosto.respuesta[0],
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getPrecios(IdPresentacion) {
        fetch(`/api/presentaciones/precios/${IdPresentacion}`)
            .then(response => response.json())
            .then(precios => {
                    this.setState({
                        precios: precios.respuesta,
                        isLoading: false
                    })
                }
            ).catch(error => this.setState({error, isLoading: false}));
    }

    getLotes() {
        fetch(`/api/productos/lotes/${this.props.IdProducto}`)
            .then(response => response.json())
            .then(lotes =>
                this.setState({lotes: lotes.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getUnidades() {
        fetch("/api/productos/unidades")
            .then(response => response.json())
            .then(unidades =>
                this.setState({unidades: unidades.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getPreciosInactivoFirstId(arreglo) {
        if (arreglo[0]) {
            this.setState({
                NuevoNombrePrecio: arreglo[0].IdPrecioPlantilla
            });
        }
    }

    getPreciosInactivos(IdPresentacion) {
        fetch(`/api/presentaciones/precios/inactivos/${IdPresentacion}`)
            .then(response => response.json())
            .then(preciosinactivos => {
                const resp = preciosinactivos.respuesta;
                this.getPreciosInactivoFirstId(resp);
                this.setState({
                    preciosinactivos: resp,
                    isLoading: false
                });
            })

            .catch(error => this.setState({error, isLoading: false}));
    }

    getMonedas() {
        fetch("/api/presentaciones/monedas")
            .then(response => response.json())
            .then(monedas =>
                this.setState({monedas: monedas.respuesta, isLoading: false})
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    clearInputsNewPrecio() {
        this.setState({
            NuevoPrecio: "",
            NuevasObservaciones: "",
            NuevaIdMoneda: 1, //Puesto Soles por Defecto para inicializar el Formulario
            NuevoPorcentaje: "",
            NuevaCantidad: "",
            NuevoPrecioIGV: ""
        });
    }

    getAfectacionProducto(IdProducto) {
        fetch(`/api/productos/getafectacion/${IdProducto}`)
            .then(response => response.json())
            .then(afectacion => {
                let resp = afectacion.respuesta[0].IdAfectacionIGV;
                let afecto = null;
                if (resp <= 8 || resp === 19) {
                    afecto = true;
                } else {
                    afecto = false;
                }
                this.setState({
                    AfectacionProducto: afecto,
                    isLoading: false
                });
            })

            .catch(error => this.setState({error, isLoading: false}));
    }

    updatePresentacion(IdPresentacion) {
        let validartipocambio = new RegExp(/^\d{1,10}(\.\d{1,3})?$/);
        let validarnombre = new RegExp(/^(?!\s*$).{1,250}$/);
        if (!this.state.presentacion.Medida.toString().match(validartipocambio)) {
            swal("ERROR", "Cantidad de Unidades  incorrecto", "error");
        } else if (!this.state.presentacion.Nombre.match(validarnombre)) {
            swal("ERROR", "Nombre incorrecto", "error");
        } else {
            fetch(`/api/presentaciones/update/${IdPresentacion}`, {
                method: "POST",
                body: JSON.stringify({
                    IdUnidad: this.state.presentacion.IdUnidad,
                    Nombre: this.state.presentacion.Nombre,
                    Descripcion: this.state.presentacion.Descripcion,
                    IdLote: this.state.presentacion.IdLote,
                    TipoCambio: this.state.presentacion.Medida,
                    CodigoBarras: this.state.presentacion.CodigoBarras,
                    EsFacturable: this.state.presentacion.EsFacturable,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(data => {
                    this.setState({isLoading: false});
                    return data;
                })
                .then(response => response.json())
                .then(responseData => {
                    const [[table_data], [header]] = responseData;
                    let error = false;
                    if (header._estado) error = header._estado;
                    else if (header._estadoInsertarPresentacion !== true)
                        error = header._estadoInsertarPresentacion;
                    if (error) {
                        swal("ERROR", error, "error");
                    } else {
                        this.setState({
                            mensajedeactualizacion: "Se ha actualizó correctamente"
                        });
                        setTimeout(() => {
                            this.setState({
                                mensajedeactualizacion: ""
                            });
                        }, 5000);
                        this.props.getPresentaciones();
                    }
                })
                .catch(error => this.setState({error, isLoading: false}));
        }
    }

    handleSubmitActualizarPresentacion = async e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        try {
            const IdPresentacion = this.props.IdPresentacion;
            await this.updatePresentacion(IdPresentacion);

            this.setState({
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    };

//Precios Update2
    updatePrecio2(precioModificado) {
        let validarprecio = new RegExp(/^\d{1,20}(\.\d{1,20})?$/);
        let validarcantidad = new RegExp(/^\d{1,16}(\.\d{1,16})?$/);
        let validarporcentaje = new RegExp(/^[-]?\d{1,16}(\.\d{1,16})?$/);
        let validaridmoneda = new RegExp(/^\d$/);
        let precioTemp
        this.state.precios.map(precio => {
            if (precio.IdPrecio == precioModificado.IdPrecio) {
                console.log(precioModificado)
                precioTemp = precio
            }
        })

        if (!precioTemp.Precio.toString().match(validarprecio)) {
            swal("ERROR", "Precio incorrecto", "error");
        } else if (!precioTemp.Cantidad.toString().match(validarcantidad)) {
            swal("ERROR", "Cantidad Incorrecta", "error");
        } else if (
            precioTemp.IdPrecioPlantilla !== 1 &&
            !precioTemp.Porcentaje.toString().match(validarporcentaje)
        ) {
            swal("ERROR", "Porcentaje incorrecto", "error");
        } else if (!precioTemp.IdMoneda.toString().match(validaridmoneda)) {
            swal("ERROR", "Moneda incorrecta", "error");
        } else if ((precioTemp.Precio < this.state.precioCosto.Precio) && precioTemp.IdPrecioPlantilla !== 1) {
            swal("ERROR", "Precio incorrecto, es menor al Precio de Costo", "error");
        } else {
            fetch("/api/presentaciones/precios/new", {
                method: "POST",
                body: JSON.stringify({
                    IdPrecio: precioTemp.IdPrecio,
                    IdPresentacion: this.props.IdPresentacion,
                    IdPrecioPlantilla: precioTemp.IdPrecioPlantilla,
                    IdMoneda: precioTemp.IdMoneda,
                    Precio: precioTemp.Precio,
                    Porcentaje: precioTemp.Porcentaje / 100,
                    Observaciones: precioTemp.Observaciones,
                    Cantidad: precioTemp.Cantidad
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(this.setState({isLoading: false}))
                .then(response => response.json())

                .then(responseData => {
                    const [[table_data], [header]] = responseData;
                    let error = false;
                    if (header._estado) error = header._estado;
                    else if (header._estadoInsertarPrecio !== true)
                        error = header._estadoInsertarPrecio;
                    if (error) {
                        swal("ERROR", error, "error");
                        // this.getPrecios(this.props.IdPresentacion);
                        // this.getPrecioCosto(this.props.IdPresentacion);
                    } else {
                        this.setState({
                            mensajedeactualizacionprecio: "Se ha actualizó correctamente"
                        });
                        swal("SUCCESS", "Se ha actualizó correctamente", "success")
                        setTimeout(() => {
                            this.setState({
                                mensajedeactualizacionprecio: ""
                            });
                        }, 5000);
                        this.getPrecios(this.props.IdPresentacion);
                        this.getPrecioCosto(this.props.IdPresentacion);
                    }
                })
                .catch(error => this.setState({error, isLoading: false}));
        }

        // let validarprecio = new RegExp(/^\d{1,16}(\.\d{1,6})?$/);
        // let validarcantidad = new RegExp(/^\d{1,16}(\.\d{1,6})?$/);
        // let validarporcentaje = new RegExp(/^[-]?\d{1,16}(\.\d{1,6})?$/);
        // let validaridmoneda = new RegExp(/^\d$/);

        // if (!this.state.precio.Precio.toString().match(validarprecio)) {
        //   swal("ERROR", "Precio incorrecto", "error");
        // } else if (!this.state.precio.Cantidad.toString().match(validarcantidad)) {
        //   swal("ERROR", "Cantidad Incorrecta", "error");
        // } else if (
        //   this.state.precio.IdPrecioPlantilla !== 1 &&
        //   !this.state.precio.Porcentaje.toString().match(validarporcentaje)
        // ) {
        //   swal("ERROR", "Porcentaje incorrecto", "error");
        // } else if (!this.state.precio.IdMoneda.toString().match(validaridmoneda)) {
        //   swal("ERROR", "Moneda incorrecta", "error");
        // }else  if ((this.state.precio.Precio < this.state.precioCosto.Precio) && this.state.precio.IdPrecioPlantilla !== 1){
        //   swal("ERROR", "Precio incorrecto, es menor al Precio de Costo", "error");
        // } else {
        //   fetch("/api/presentaciones/precios/new", {
        //     method: "POST",
        //     body: JSON.stringify({
        //       IdPrecio: this.state.IdPrecio,
        //       IdPresentacion: this.props.IdPresentacion,
        //       IdPrecioPlantilla: this.state.precio.IdPrecioPlantilla,
        //       IdMoneda: this.state.precio.IdMoneda,
        //       Precio: this.state.precio.Precio,
        //       Porcentaje: this.state.precio.Porcentaje / 100,
        //       Observaciones: this.state.precio.Observaciones,
        //       Cantidad: this.state.precio.Cantidad
        //     }),
        //     headers: {
        //       Accept: "application/json",
        //       "Content-Type": "application/json"
        //     }
        //   })
        //     .then(this.setState({ isLoading: false }))
        //     .then(response => response.json())

        //     .then(responseData => {
        //       const [[table_data], [header]] = responseData;
        //       let error = false;
        //       if (header._estado) error = header._estado;
        //       else if (header._estadoInsertarPrecio !== true)
        //         error = header._estadoInsertarPrecio;
        //       if (error) {
        //         swal("ERROR", error, "error");
        //       } else {
        //         this.setState({
        //           mensajedeactualizacionprecio: "Se ha actualizó correctamente"
        //         });
        //         setTimeout(() => {
        //           this.setState({
        //             mensajedeactualizacionprecio: ""
        //           });
        //         }, 5000);
        //         this.getPrecios(this.props.IdPresentacion);
        //         this.getPrecioCosto(this.props.IdPresentacion);
        //       }
        //     })
        //     .catch(error => this.setState({ error, isLoading: false }));
        // }
    }

    //Precios Update
    updatePrecio() {
        let validarprecio = new RegExp(/^\d{1,16}(\.\d{1,6})?$/);
        let validarcantidad = new RegExp(/^\d{1,16}(\.\d{1,6})?$/);
        let validarporcentaje = new RegExp(/^[-]?\d{1,16}(\.\d{1,6})?$/);
        let validaridmoneda = new RegExp(/^\d$/);

        if (!this.state.precio.Precio.toString().match(validarprecio)) {
            swal("ERROR", "Precio incorrecto", "error");
        } else if (!this.state.precio.Cantidad.toString().match(validarcantidad)) {
            swal("ERROR", "Cantidad Incorrecta", "error");
        } else if (
            this.state.precio.IdPrecioPlantilla !== 1 &&
            !this.state.precio.Porcentaje.toString().match(validarporcentaje)
        ) {
            swal("ERROR", "Porcentaje incorrecto", "error");
        } else if (!this.state.precio.IdMoneda.toString().match(validaridmoneda)) {
            swal("ERROR", "Moneda incorrecta", "error");
        } else if ((this.state.precio.Precio < this.state.precioCosto.Precio) && this.state.precio.IdPrecioPlantilla !== 1) {
            swal("ERROR", "Precio incorrecto, es menor al Precio de Costo", "error");
        } else {
            fetch("/api/presentaciones/precios/new", {
                method: "POST",
                body: JSON.stringify({
                    IdPrecio: this.state.IdPrecio,
                    IdPresentacion: this.props.IdPresentacion,
                    IdPrecioPlantilla: this.state.precio.IdPrecioPlantilla,
                    IdMoneda: this.state.precio.IdMoneda,
                    Precio: this.state.precio.Precio,
                    Porcentaje: this.state.precio.Porcentaje / 100,
                    Observaciones: this.state.precio.Observaciones,
                    Cantidad: this.state.precio.Cantidad
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(this.setState({isLoading: false}))
                .then(response => response.json())

                .then(responseData => {
                    const [[table_data], [header]] = responseData;
                    let error = false;
                    if (header._estado) error = header._estado;
                    else if (header._estadoInsertarPrecio !== true)
                        error = header._estadoInsertarPrecio;
                    if (error) {
                        swal("ERROR", error, "error");
                    } else {
                        this.setState({
                            mensajedeactualizacionprecio: "Se ha actualizó correctamente"
                        });
                        setTimeout(() => {
                            this.setState({
                                mensajedeactualizacionprecio: ""
                            });
                        }, 5000);
                        this.getPrecios(this.props.IdPresentacion);
                        this.getPrecioCosto(this.props.IdPresentacion);
                    }
                })
                .catch(error => this.setState({error, isLoading: false}));
        }
    }

    handleSubmitPrecio = async e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        try {
            await this.updatePrecio();
            this.setState({
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    };
    handleSubmitPrecio2 = async (e, precio) => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        try {
            await this.updatePrecio2(precio);
            this.setState({
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    };

    //Precios Insert
    newPrecio() {
        let validarprecio = new RegExp(/^\d{1,16}(\.\d{1,6})?$/);
        let validarcantidad = new RegExp(/^\d{1,16}(\.\d{1,6})?$/);
        let validarporcentaje = new RegExp(/^[-]?\d{1,16}(\.\d{1,6})?$/);
        let validaridmoneda = new RegExp(/^\d$/);

        if (!this.state.NuevoPrecio.toString().match(validarprecio)) {
            swal("ERROR", "Precio incorrecto", "error");
        } else if (
            this.state.NuevaCantidad === null ||
            !this.state.NuevaCantidad.toString().match(validarcantidad)
        ) {
            swal("ERROR", "Cantidad Incorrecta", "error");
        } else if (
            !this.state.NuevoPorcentaje.toString().match(validarporcentaje)
        ) {
            swal("ERROR", "Porcentaje incorrecto", "error");
        } else if (!this.state.NuevaIdMoneda.toString().match(validaridmoneda)) {
            swal("ERROR", "Moneda incorrecta", "error");
        } else if (this.state.NuevoPrecio < this.state.precioCosto.Precio) {
            swal("ERROR", "Precio incorrecto, es menor al Precio de Costo", "error");
        } else {
            fetch("/api/presentaciones/precios/new", {
                method: "POST",
                body: JSON.stringify({
                    IdPrecio: null,
                    IdPresentacion: this.props.IdPresentacion,
                    IdPrecioPlantilla: this.state.NuevoNombrePrecio,
                    IdMoneda: this.state.NuevaIdMoneda,
                    Precio: this.state.NuevoPrecio,
                    Porcentaje: this.state.NuevoPorcentaje / 100,
                    Observaciones: this.state.NuevasObservaciones,
                    Cantidad: this.state.NuevaCantidad
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(this.setState({isLoading: false}))
                .then(response => response.json())

                .then(responseData => {
                    const [[table_data], [header]] = responseData;
                    let error = false;
                    if (header._estado) error = header._estado;
                    else if (header._estadoInsertarPrecio !== true)
                        error = header._estadoInsertarPrecio;
                    if (error) {
                        swal("ERROR", error, "error");
                    } else {
                        this.setState({
                            mensajedenewprecio: "Se guardó correctamente"
                        });
                        setTimeout(() => {
                            this.setState({
                                mensajedenewprecio: ""
                            });
                        }, 5000);
                        this.clearInputsNewPrecio();
                        this.getPrecios(this.props.IdPresentacion);
                        this.getPreciosInactivos(this.props.IdPresentacion);
                    }
                })
                .catch(error => this.setState({error, isLoading: false}));
        }
    }

    handleSubmitNewPrecio = async e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        try {
            await this.newPrecio();
            this.setState({
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    };

    async deletePrecio(IdPrecio) {
        await fetch(`/api/presentaciones/precios/delete/${IdPrecio}`, {
            method: "POST",
            body: JSON.stringify({
                IdPrecio: IdPrecio
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(data => {
                this.setState({isLoading: false});
                return data;
            })
            .then(response => response.json())
            .then(responseData => {
                this.setState({respuestaDeletePrecio: responseData.respuesta});
                if (this.state.respuestaDeletePrecio === true) {
                    swal(
                        "¡Borrado!",
                        "¡El Precio ha sido borrado satisfactoriamente!",
                        "success"
                    );
                }
            })
            .catch(error => this.setState({error, isLoading: false}));
        await this.getPrecios(this.props.IdPresentacion);
    }

    handleOnDeletePrecio(IdPrecio) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Desea borrar éste Precio?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete) {
                await this.deletePrecio(IdPrecio);
            }

            this.setState({
                IdPrecio: IdPrecio
            });
        };
    }

    handleSavePDF(nombreProducto, nombrePresentacion) {
        return () => {
            let doc = new jsPDF();
            var pageHeight =
                doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            var pageWidth =
                doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
            doc.setFontSize(14);
            doc.text(nombrePresentacion + " - " + nombreProducto, 14, 10);
            doc.autoTable({
                body: this.state.precios,
                columns: [
                    {header: "Nombre del Precio", dataKey: "Nombre"},
                    {header: "Precio sin IGV", dataKey: "Precio"},
                    {header: "Precio con IGV", dataKey: "PrecioIGV"},
                    {header: "Porcentaje", dataKey: "Porcentaje"},
                    {header: "Cantidad", dataKey: "Cantidad"},
                    {header: "Moneda", dataKey: "Abreviatura"},
                    {header: "Observaciones", dataKey: "Observaciones"}
                ]
            });
            doc.setFontSize(10);
            doc.setFontType("italic");
            doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

            doc.save(`reporte_precios_${nombrePresentacion}_${nombreProducto}.pdf`);
        };
    }

    render() {
        const {isLoading, error} = this.state;
        if (isLoading) {
            return <PageLoading/>;
        }
        if (error) {
            return <PageError error={this.state.error}/>;
        }
        return (
            <VerDetallesPresentacionUI
                {...this.state}
                handleNombre={this.handleNombreChange}
                handleDescripcion={this.handleDescripcionChange}
                handleMedida={this.handleTipoCambioChange}
                handleLote={this.handleLoteChange}
                handleUnidad={this.handleUnidadChange}
                actualizarPresentacion={this.handleSubmitActualizarPresentacion}
                onClick={this.handleOnClick}
                handleNuevasObservaciones={this.handleNuevasObservacionesChange}
                handleNuevoPrecio={this.handleNuevoPrecioChange}
                handleNuevoNombrePrecio={this.handleNuevoNombrePrecioChange}
                handleObservacionesPrecio={this.handleObservacionesChange}
                handlePrecio={this.handlePrecioChange}
                handleNombrePrecio={this.handleNombrePrecioChange}
                actualizarPrecio={this.handleSubmitPrecio}
                actualizarPrecio2={this.handleSubmitPrecio2}
                handleSubmitNewPrecio={this.handleSubmitNewPrecio}
                handleOnDeletePrecio={this.handleOnDeletePrecio}
                nombreProducto={this.props.nombreProducto}
                idProducto={this.props.IdProducto}
                pdfViewer={this.handleSavePDF}
                handleMoneda={this.handleMonedaChange}
                handlePorcentaje={this.handlePorcentajeChange}
                handleNuevaMoneda={this.handleNuevaMonedaChange}
                handleNuevoPorcentaje={this.handleNuevoPorcentajeChange}
                handleCantidad={this.handleCantidadChange}
                handleNuevaCantidad={this.handleNuevaCantidadChange}
                handleNuevoPrecioIGV={this.handleNuevoPrecioIGVChange}
                handlePrecioIGV={this.handlePrecioIGVChange}
                handleCodigoBarras={this.handleCodigoBarras}
                handleEsFacturable={this.handleEsFacturableChange}
            />
        );
    }
}

export default VerDetallesPresentacion;
