import jsPDF from "jspdf";
import { Style, Section } from "../../pages/RegistroVentas/ClasesImpresion";
import { loadLogoByRUC } from "../../helpers/funcionesGlobales";
import printBlob from "../../helpers/printBlob";
import moment from "moment";
import { decimalAdjust } from "../../Global";
import { calcularVentaDetalleMovPDF } from "../../helpers/calcularVenta";


function calcularTotales(items) {
    var lista = items.map(item => {
        const {
            Cantidad,
            Precio,
            Flete
        } = item
        return ({
            PrecioVenta: parseFloat(Precio),
            Tributos: [1],
            TasaISC: 0,
            Cantidad: parseFloat(Cantidad),
            Gratuito: 0,
            Descuento: 0,
            IdAfectacionIgv: 1,
            Flete: parseFloat(Flete),
        })
    })

    const detalle = {
        Items: lista
    }

    return calcularVentaDetalleMovPDF(detalle)
}

export async function printTicket(detalles, movimiento, extImg = 'png') {
    const isNota = movimiento.IdTipoDocumentoSunat == 52 || movimiento.IdTipoDocumentoSunat == 53;

    if (isNota) {
        printNotaIngresoEgreso(detalles, movimiento, extImg);
    } else {
        printMovimientoAlmacen(detalles, movimiento, extImg)
    }
}


export async function printNotaIngresoEgreso(detalles, movimiento, extImg = 'png') {
    const DetalleVentaFormateado = detalles.map(item => {
        return {
            ...item,
            NombreProducto: item.NomProducto,
            Cantidad: item.Cantidad,
        };
    });

    const mmPageSize = [80, 3276];
    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);

    var doc = new jsPDF({
        orientation: "potrait",
        unit: "mm",
        format: ptPageSize
    });

    const pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    const logo = await loadLogoByRUC(movimiento.RucEmpresa, 'logo', extImg);
    doc.addImage(logo, "PNG", 25, 1, 30, 19);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, 20, 76, 20);

    const EmpresaTittleStyle = new Style(9, "normal", 1, "center");
    const EmpresaTittleSection = new Section(
        doc,
        10,
        21,
        EmpresaTittleStyle,
        60
    );

    EmpresaTittleSection.write(movimiento.RucEmpresa, EmpresaTittleStyle);
    EmpresaTittleSection.write(movimiento.NombreEmpresa.toUpperCase(), EmpresaTittleStyle);

    const EmpresaDataStyle = new Style(7, "normal", 1, "center");
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.y - 2,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    EmpresaDataSection.write(movimiento.DireccionEmpresa.toUpperCase());
    EmpresaDataSection.write(movimiento.NombreSucursal.toUpperCase());
    EmpresaDataSection.write(movimiento.DireccionSucursal.toUpperCase());

    doc.line(4, EmpresaDataSection.y - 2, 76, EmpresaDataSection.y - 2);

    const ComprobanteStyle = new Style(10, "bold", 1, "center");
    const ComprobanteSection = new Section(doc, 10, EmpresaDataSection.y - 1, ComprobanteStyle, 60);

    const ComprobanteSection2 = new Section(
        doc,
        10,
        ComprobanteSection.y + 3.5,
        ComprobanteStyle,
        ComprobanteSection.width
    );

    ComprobanteSection.write("MOVIMIENTO DE ALMACÉN");
    ComprobanteSection2.write(movimiento.Numero.toUpperCase());
    
    doc.setDrawColor(0, 0, 0);
    doc.line(4, ComprobanteSection.y + 0.5, 76, ComprobanteSection.y + 0.5);

    const negritaStyle = new Style(8, "bold", 1);
    const newLineStyle = new Style(4, "bold");
    const clienteStyle = new Style(8, "normal", 1);
    const clienteSection = new Section(
        doc,
        4,
        ComprobanteSection2.y - 2,
        clienteStyle,
        pageWidth - 6
    );

    clienteSection.write(`FECHA DE EMISIÓN: ${moment(movimiento.FechaMovimiento).format("DD-MM-YYYY HH:mm")}`);
    if (movimiento.Usuario) {
        clienteSection.write(`USUARIO: ${movimiento.Usuario != "" ? movimiento.Usuario : ""}`);
    }
    clienteSection.write(`TIPO DE MOVIMIENTO: ${movimiento.NomTipoMovimiento}`);
    clienteSection.write(`DOC. ASOCIADO: ${movimiento.NomTipoDocumentoSunat.toUpperCase()}`);
    clienteSection.write(`NRO. DOCUMENTO: ${movimiento.NroDocumentoSunat.toUpperCase()}`);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, clienteSection.y - 1, 76, clienteSection.y - 1);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, clienteSection.y + 4.5, 76, clienteSection.y + 4.5);

    doc.autoTable({
        body: DetalleVentaFormateado,
        bodyStyles: {
            fontSize: 9,
            fontStyle: "normal",
            cellPadding: 1
        },
        headStyles: {fontSize: 6.9},
        startY: clienteSection.y - 2,
        showHead: "firstPage",
        margin: {
            right: 3,
            left: 3,
        },
        rowPageBreak: "avoid",
        columnStyles: {
            NombreProducto: {columnWidth: 30, fontSize: 8},
            Cantidad: {columnWidth: 5,fontSize: 8},
        },
        styles: {
            overflow: "linebreak",
            tableWidth: "auto",
            columnWidth: "auto",
            halign: "left"
        },
        columns: [
            {header: "PROD", dataKey: "NombreProducto"},
            {header: "CANT", dataKey: "Cantidad"},
        ],
        theme: "plain"
    });
    
    doc.setFontSize(10);
    doc.setFontType("italic");

    let finalTable = doc.autoTable.previous.finalY - 1;
    doc.setDrawColor(0, 0, 0);
    doc.line(4, finalTable, 76, finalTable);

    const ObservacionStyle = new Style(7, "normal", 1);
    const ObservacionSection = new Section(
        doc,
        4,
        0,
        ObservacionStyle,
        72
    );

    ObservacionSection.y = finalTable + 2;
    ObservacionSection.write(`OBSERVACIÓN: ${movimiento.Glosa || ""}`);

    const heightForSignatureSquare = 35;

    const AprobadoPorStyle = new Style(7, "normal", 1);
    const AprobadoPorSection = new Section(
        doc,
        4,
        ObservacionSection.y + 1,
        AprobadoPorStyle,
        72
    );

    AprobadoPorSection.write(`APROBADO POR: `);
    doc.rect(4, ObservacionSection.y + 4, pageWidth - 8, heightForSignatureSquare);
    
    const EntregadoPorStyle = new Style(7, "normal", 1);
    const EntregadoPorSection = new Section(
        doc,
        4,
        AprobadoPorSection.y + heightForSignatureSquare,
        EntregadoPorStyle,
        72
    );

    EntregadoPorSection.write(`ENTREGADO POR: `);
    doc.rect(4, EntregadoPorSection.y - 1, pageWidth - 8, heightForSignatureSquare);

    const RecibidoPorStyle = new Style(7, "normal", 1);
    const RecibidoPorSection = new Section(
        doc,
        4,
        EntregadoPorSection.y + heightForSignatureSquare,
        RecibidoPorStyle,
        72
    );

    RecibidoPorSection.write(`RECIBIDO POR: `);
    doc.rect(4, RecibidoPorSection.y - 1, pageWidth - 8, heightForSignatureSquare);

    doc.autoPrint();
    printBlob(doc.output("bloburl"));
}


export async function printMovimientoAlmacen(detalles, movimiento, extImg = 'png') {
    const DetalleVentaFormateado = detalles.map(item => {
        return {
            ...item,
            NombreProducto: item.NomProducto,
            Cantidad: item.Cantidad,
            Precio: item.Precio,
            Total: decimalAdjust('floor', item.Importe, -2),
        };
    });

    const mmPageSize = [80, 3276];
    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);

    var doc = new jsPDF({
        orientation: "potrait",
        unit: "mm",
        format: ptPageSize
    });

    const pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    const logo = await loadLogoByRUC(movimiento.RucEmpresa, 'logo', extImg);
    doc.addImage(logo, "PNG", 25, 1, 30, 19);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, 20, 76, 20);

    const EmpresaTittleStyle = new Style(9, "normal", 1, "center");
    const EmpresaTittleSection = new Section(
        doc,
        10,
        21,
        EmpresaTittleStyle,
        60
    );

    EmpresaTittleSection.write(movimiento.RucEmpresa, EmpresaTittleStyle);
    EmpresaTittleSection.write(movimiento.NombreEmpresa.toUpperCase(), EmpresaTittleStyle);

    const EmpresaDataStyle = new Style(7, "normal", 1, "center");
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.y - 2,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    EmpresaDataSection.write(movimiento.DireccionEmpresa.toUpperCase());
    EmpresaDataSection.write(movimiento.NombreSucursal.toUpperCase());
    EmpresaDataSection.write(movimiento.DireccionSucursal.toUpperCase());

    doc.line(4, EmpresaDataSection.y - 2, 76, EmpresaDataSection.y - 2);

    const ComprobanteStyle = new Style(10, "bold", 1, "center");
    const ComprobanteSection = new Section(doc, 10, EmpresaDataSection.y - 1, ComprobanteStyle, 60);

    const ComprobanteSection2 = new Section(
        doc,
        10,
        ComprobanteSection.y + 3.5,
        ComprobanteStyle,
        ComprobanteSection.width
    );

    ComprobanteSection.write("MOVIMIENTO DE ALMACÉN");
    ComprobanteSection2.write(movimiento.Numero.toUpperCase());
    
    doc.setDrawColor(0, 0, 0);
    doc.line(4, ComprobanteSection.y + 0.5, 76, ComprobanteSection.y + 0.5);

    const negritaStyle = new Style(8, "bold", 1);
    const newLineStyle = new Style(4, "bold");
    const clienteStyle = new Style(8, "normal", 1);
    const clienteSection = new Section(
        doc,
        4,
        ComprobanteSection2.y - 2,
        clienteStyle,
        pageWidth - 6
    );

    clienteSection.write(`FECHA DE EMISIÓN: ${moment(movimiento.FechaMovimiento).format("DD-MM-YYYY HH:mm")}`);
    if (movimiento.Usuario) {
        clienteSection.write(`USUARIO: ${movimiento.Usuario != "" ? movimiento.Usuario : ""}`);
    }    
    clienteSection.write(`TIPO DE MOVIMIENTO: ${movimiento.NomTipoMovimiento}`);
    clienteSection.write(`DOC. ASOCIADO: ${movimiento.NomTipoDocumentoSunat.toUpperCase()}`);
    clienteSection.write(`NRO. DOCUMENTO: ${movimiento.NroDocumentoSunat.toUpperCase()}`);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, clienteSection.y - 1, 76, clienteSection.y - 1);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, clienteSection.y + 4.5, 76, clienteSection.y + 4.5);

    doc.autoTable({
        body: DetalleVentaFormateado,
        bodyStyles: {
            fontSize: 9,
            fontStyle: "normal",
            cellPadding: 1
        },
        headStyles: {fontSize: 6.9},
        startY: clienteSection.y - 2,
        showHead: "firstPage",
        margin: {
            right: 3,
            left: 3,
        },
        rowPageBreak: "avoid",
        columnStyles: {
            NombreProducto: {columnWidth: 30, fontSize: 8},
            Cantidad: {columnWidth: 5,fontSize: 8},
            Precio: {columnWidth: 5, halign: "right",fontSize: 8},
            Total: {columnWidth: 5, halign: "right",fontSize: 8},
        },
        styles: {
            overflow: "linebreak",
            tableWidth: "auto",
            columnWidth: "auto",
            halign: "left"
        },
        columns: [
            {header: "PROD", dataKey: "NombreProducto"},
            {header: "CANT", dataKey: "Cantidad"},
            {header: "PRECIO", dataKey: "Precio"},
            {header: "TOTAL", dataKey: "Total"},
        ],
        theme: "plain"
    });
    
    doc.setFontSize(10);
    doc.setFontType("italic");

    let finalTable = doc.autoTable.previous.finalY - 1;
    doc.setDrawColor(0, 0, 0);
    doc.line(4, finalTable, 76, finalTable);

    const totalesStyle = new Style(10, "bold", 1.15, "right");
    const totalesTittleSection = new Section(doc, 21, 0, totalesStyle, 27);
    totalesTittleSection.paddingY = 0.5

    let TotalesTitle = [
        "SUB TOTAL S/",
        "IGV S/",
        "FLETE S/",
        "TOTAL S/",
    ];

    totalesTittleSection.y = finalTable + 1;
    totalesTittleSection.write(TotalesTitle);
    const totalesMontoSection = new Section(doc, 50, 50, totalesStyle, 23);

    const totalesMA = calcularTotales(detalles);
    const v_SubTotal = totalesMA.Gravadas.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const v_IGV = totalesMA.IGV.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const v_Flete = totalesMA.Flete.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const v_Total = totalesMA.Total.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    let totales = [
        v_SubTotal,
        v_IGV,
        v_Flete,
        v_Total,
    ];

    totalesMontoSection.y = finalTable + 1;
    totalesMontoSection.write(totales);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, totalesMontoSection.y - 1, 76, totalesMontoSection.y - 1);

    const ObservacionStyle = new Style(7, "normal", 1);
    const ObservacionSection = new Section(
        doc,
        4,
        totalesMontoSection.y + 1,
        ObservacionStyle,
        72
    );

    ObservacionSection.write(`OBSERVACIÓN: ${movimiento.Glosa || ""}`);

    const heightForSignatureSquare = 35;

    const AprobadoPorStyle = new Style(7, "normal", 1);
    const AprobadoPorSection = new Section(
        doc,
        4,
        ObservacionSection.y + 1,
        AprobadoPorStyle,
        72
    );

    AprobadoPorSection.write(`APROBADO POR: `);
    doc.rect(4, ObservacionSection.y + 4, pageWidth - 8, heightForSignatureSquare);
    
    const EntregadoPorStyle = new Style(7, "normal", 1);
    const EntregadoPorSection = new Section(
        doc,
        4,
        AprobadoPorSection.y + heightForSignatureSquare,
        EntregadoPorStyle,
        72
    );

    EntregadoPorSection.write(`ENTREGADO POR: `);
    doc.rect(4, EntregadoPorSection.y - 1, pageWidth - 8, heightForSignatureSquare);

    const RecibidoPorStyle = new Style(7, "normal", 1);
    const RecibidoPorSection = new Section(
        doc,
        4,
        EntregadoPorSection.y + heightForSignatureSquare,
        RecibidoPorStyle,
        72
    );

    RecibidoPorSection.write(`RECIBIDO POR: `);
    doc.rect(4, RecibidoPorSection.y - 1, pageWidth - 8, heightForSignatureSquare);

    doc.autoPrint();
    printBlob(doc.output("bloburl"));
}
