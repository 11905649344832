import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactExport from "react-data-export";
import "react-moment";
import SelectSucursal from '../PuntoVenta/SucursalesLista';
import {convertStrToJson, decimalAdjust} from "../../Global";
import Modal from "../clientes/ClienteModal";
import { EnvioOperadorMasivo } from './EnvioOperadorMasivo';
import moment from "moment";

import("react-month-picker-input/dist/react-month-picker-input.css");


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function getDataExportExcel(dataSet) {
    return [
        {
            columns: [
                {
                    title: "CodigoUnicoOperacion",
                    width: {wpx: 90}
                },
                {
                    title: "FechaEmision",
                    width: {wpx: 100}
                },
                {
                    title: "FechaVencimiento",
                    width: {wpx: 100}
                },
                {
                    title: "TipoComprobante",
                    width: {wpx: 100}
                },
                {
                    title: "Serie",
                    width: {wpx: 60}
                },
                {
                    title: "Numero Comprobante",
                    width: {wpx: 100}
                },
                {
                    title: "Modalidad de Pago",
                    width: {wpx: 100}
                },
                {
                    title: "Cantidad de Días",
                    width: {wpx: 100}
                },
                {
                    title: "Fecha Pago Crédito",
                    width: {wpx: 100}
                },
                {
                    title: "Estado del Comprobante",
                    width: {wpx: 100}
                },
                {
                    title: "NroTipoDocumentoCliente",
                    width: {wpx: 120}
                },
                {
                    title: "RazonSocial",
                    width: {wpx: 200}
                },
                {
                    title: "Exportacion",
                    width: {wpx: 100}
                },
                {
                    title: "Gravadas",
                    width: {wpx: 100}
                },
                {
                    title: "Exoneradas",
                    width: {wpx: 100}
                },
                {
                    title: "Inafectas",
                    width: {wpx: 100}
                },
                {
                    title: "ISC",
                    width: {wpx: 100}
                },
                {
                    title: "IGV",
                    width: {wpx: 100}
                },
                {
                    title: "ICBPER",
                    width: {wpx: 100}
                },
                {
                    title: "DESC. ITEMS.",
                    width: {wpx: 100}
                },
                {
                    title: "Total",
                    width: {wpx: 100}
                },
                {
                    title: "Redondeo",
                    width: {wpx: 100}
                },
                {
                    title: "TotalRedondeo",
                    width: {wpx: 100}
                },
                {
                    title: "TipoCambio",
                    width: {wpx: 100}
                },
                {
                    title: "FechaComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "CodigoTipoComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "SerieComprobanteModificado",
                    width: {wpx: 100}
                },
                {
                    title: "NumeroComprobanteModificado",
                    width: {wpx: 100}
                }
            ],

            data: dataSet.map(row => {
                return [
                    {
                        value: row.CodigoUnicoOperacion,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaEmision,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaVencimiento,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Serie,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.IdModalidadPago,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.CantidadDiasCredito,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaPago,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Estado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NroTipoDocumentoCliente,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.RazonSocial,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Exportacion || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Gravadas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Exoneradas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Inafectas || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.ISC || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.IGV || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.ICBPER || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.descItems,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Total,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Redondeo || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TotalRedondeo || 0.0,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoCambio,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.FechaComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.CodigoTipoComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.SerieComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobanteModificado,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    }
                ];
            })
        }
    ];
}

function getCEAnulacion(row) {
    return {
        cabecera:
            {
                IdRegistroVenta: row.IdRegistroVenta,
                NombreArchivo: "",
                FechaGeneracionDocumento: "",
                FechaComunicacion: "",
                RUC: "",
                RazonSocial: "",
                TipoDocumento: "",
            },
        items: [
            {
                TipoComprobante: row.CodTipoComprobante,
                FechaEmision: row.FechaEmision,
                Serie: row.Serie,
                NroComprobante: row.NumeroComprobante,
                Motivo: "CANCELACION",
            }
        ]
    }
}

const RegistrosVentasUI = props => {
    const [attempt, setAttempt] = useState(0)
    const [canShowPaymentInfo, setCanShowPaymentInfo] = useState(false);
    const [canChangeDateForRecords, setCanChangeDateForRecords] = useState(false);

      useEffect(() => {
        checkPermissions();
      }, [])

      const checkPermissions = async () => {
        try {
          const req = await fetch(`/api/pre-invoice/config`);

          if (!req.ok) {
            throw new Error(await req.text())
          }

          const res = await req.json()

          if ('canShowPaymentInfo' in res) {
            setCanShowPaymentInfo(!!res.canShowPaymentInfo)
          }

          if ('canChangeDateForRecords' in res) {
            setCanChangeDateForRecords(!!res.canChangeDateForRecords)
          }
        } catch (e) {
          console.error(e)
        }
      }

    let ventas = [...props.ventas];
    const table = props.tableInfo;
    const isActiveTurn = props.isActiveTurn;

    if (props.reportePLE) {
        var dataExport = getDataExportExcel(props.reportePLE);
    }

    return (
        <section className="ventas-fpay-section-vh pt-3">
            <div className="container"
            >
                <div className="d-flex justify-content-end">
                    <div className="p-1">
                    </div>
                    <div className="p-1">
                        <button
                            className="btn btn-outline-danger"
                            onClick={e => props.pdfViewer(props.reportePLE)}
                            title="Exportar a PDF"
                            hidden={!props.mostrarBotonesImpresion} disabled={!props.mostrarBotonesImpresion}
                        >
                            {<i className="fas fa-file-pdf"></i>}
                        </button>
                    </div>

                    <div className="p-1">

                        <ExcelFile
                            element={
                                <button
                                    className="btn btn-outline-success"
                                    title="Exportar a Excel"
                                    hidden={!props.mostrarBotonesImpresion} disabled={!props.mostrarBotonesImpresion}
                                >
                                    <i className="fas fa-file-excel"></i>
                                </button>
                            }
                            filename="ReporteVentas"

                        >
                            <ExcelSheet dataSet={dataExport} name="Ventas"/>
                        </ExcelFile>
                    </div>
                </div>
                <div className=" pt-5">
                    {canChangeDateForRecords && <div className="col-12 col-sm-3 offset-sm-9 mt-3 no-pad">
                        <input
                            name="fechaBusqueda"
                            disabled={table.isLoading}
                            className="form-control"
                            onChange={props.handlefechaBusquedaChange}
                            value={props.fechaBusqueda}
                            type="date"
                        />
                    </div>}
                    <div className="col-12 mt-3 caja-fondo-blanco-m-2">
                        <h5 className="sucursal-text-color">Sucursal:</h5>
                        <SelectSucursal
                            id={props.IdSucursal}
                            dataSucursales={props.dataSucursales}
                            SucursalChange={props.SucursalChange}
                            disabled={props.FiltrarSucursal}
                        />
                    </div>
                </div>
                <div className="mt-3 mb-3 seccion-btn-verde-mfp">
                    <EnvioOperadorMasivo className="mr-2"  attempt={attempt} idSucursal={props.IdSucursal} fechaBusqueda={props.fechaBusqueda} onCompleted={() => {
                        setAttempt(attempt + 1);
                        props.handlefechaBusquedaChange({ target: { value: props.fechaBusqueda }})
                    }}/>
                    <button disabled={props.IsLoading} onClick={props.onReportHandler}
                            className="btn btn-outline-primary btn-verde-mfp">
                        {props.IsLoading ? 'Cargando' : 'Realizar reporte'}
                    </button>
                </div>
                <ReactTable
                    id="ventas"
                    data={ventas}
                    loading={table.isLoading}
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                    }
                    onFetchData={(state, instance) => props.onFilterVentas(state, instance)}
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "Opciones",
                                    id: "IdRegistroVenta",
                                    width: 50,
                                    className: "ancho-prueba",
                                    accessor: d => d.IdRegistroVenta,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IdRegistroVenta"]
                                        }),
                                    Cell: f => {
                                        let resEnvioSunat = convertStrToJson(f.original.respuestaEnvioSunat);
                                        const rv = f.original;

                                        const oldXmlUrl = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${rv.Ruc}/xml/${rv.CodigoTipoComprobante}/${rv.Ruc}-${rv.CodigoTipoComprobante}-${rv.Serie}-${rv.NumeroComprobante}.xml`;
                                        const oldCdrUrl = `${process.env.REACT_APP_FACT_BASE_URL}/datos/${rv.Ruc}/xml/${rv.CodigoTipoComprobante}/R-${rv.Ruc}-${rv.CodigoTipoComprobante}-${rv.Serie}-${rv.NumeroComprobante}.zip`;

                                        let xml = (resEnvioSunat.xmlFirmado && resEnvioSunat.xmlFirmado.length ? resEnvioSunat.xmlFirmado : oldXmlUrl).replace('http', 'https');
                                        let cdrZip = (resEnvioSunat.cdrZip && resEnvioSunat.cdrZip.length ? resEnvioSunat.cdrZip : oldCdrUrl).replace('http', 'https');
                                        let resAnulacionSunat = convertStrToJson(f.original.respuestaAnulacionSunat)

                                        return (
                                            <>
                                                <div className="dropdown drop-resp">
                                                    <button className="btn options-ventas" type="button"
                                                            data-toggle="dropdown" aria-expanded="false">

                                                    </button>
                                                    <div className="dropdown-menu py-1">
                                                        {props.isLoading ? null : (
                                                            <div className="dropdown-item px-2">
                                                                <button
                                                                    className="btn btn-primary btn-sm btn-block font-weight-bold"
                                                                    title="Enviar a Operador"
                                                                    disabled={f.original.EstadoCE === "Aceptado" || f.original.EstadoCE === "Anulado" && "true"}
                                                                    onClick={props.handleEnviarVenta(
                                                                        f.original.IdRegistroVenta
                                                                    )}
                                                                >
                                                                    Enviar a operador
                                                                </button>
                                                            </div>
                                                        )}
                                                        {xml ? (
                                                            <div className="dropdown-item px-2">
                                                                <a
                                                                    className="btn btn-success btn-sm btn-block font-weight-bold"
                                                                    target="blank"
                                                                    title={`Descargar XML`}
                                                                    href={xml}
                                                                >
                                                                    Descargar XML
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                        {cdrZip ? (
                                                            <div className="dropdown-item px-2">
                                                                <a
                                                                    className="btn btn-success btn-sm btn-block font-weight-bold"
                                                                    target="blank"
                                                                    href={cdrZip}
                                                                    title="Descargar CDR"
                                                                >
                                                                    Descargar CDR
                                                                </a>
                                                            </div>
                                                        ) : null}

                                                        {!props.isLoading && isActiveTurn && <>
                                                            <div className="dropdown-item px-2">
                                                                <button
                                                                    className="btn btn-outline-dark btn-sm btn-block font-weight-bold"
                                                                    title="Anulación interna"
                                                                    onClick={e => props.onRegresarStock(f.original)}
                                                                    disabled={f.original.regresoStock || resEnvioSunat.ok}
                                                                >
                                                                    Anulación interna
                                                                </button>
                                                            </div>
                                                        </>}

                                                        {!props.isLoading && <>
                                                            <div className="dropdown-item px-2">
                                                                <button
                                                                    className="btn btn-danger btn-sm btn-block font-weight-bold"
                                                                    title="Descargar PDF"
                                                                    onClick={e => props.onDownloadPdf(f.original.IdRegistroVenta, false)}
                                                                >
                                                                    Descargar PDF
                                                                </button>
                                                            </div>
                                                        </>}

                                                        {!props.isLoading && <>
                                                            <div className="dropdown-item px-2">
                                                                <button
                                                                    className="btn btn-info btn-sm btn-block font-weight-bold"
                                                                    title="Enviar correo"
                                                                    onClick={e => props.handleEnviarCorreo(f.original)}
                                                                    disabled={f.original.EstadoCE === "Anulado" && "true"}
                                                                >
                                                                    Enviar correo
                                                                </button>
                                                            </div>
                                                        </>}

                                                        {!props.isLoading  && f.original.FechaEmision === moment().format("DD-MM-YYYY") && <>
                                                            <div className="dropdown-item px-2">
                                                                <button
                                                                    className="btn btn-danger btn-sm btn-block font-weight-bold"
                                                                    title="Comunicación de baja"
                                                                    onClick={
                                                                        () => {
                                                                            props.permisosAnular ?
                                                                                props.openModalValidacion(getCEAnulacion(f.original))
                                                                                : props.handleEnviarAnulacion(getCEAnulacion(f.original))
                                                                        }
                                                                    }
                                                                    disabled={f.original.EstadoCE === "Anulado" || props.disableAnularBtn}
                                                                    hidden={!!f.original.EstadoFromNc || f.original.EstadoCE !== "Aceptado" }
                                                                >
                                                                    Comunicación de baja
                                                                </button>
                                                            </div>
                                                        </>}

                                                        {!props.isLoading && isActiveTurn ? (() => {
                                                            const respuestaAnulacionSunat = JSON.parse(f.original.respuestaAnulacionSunat);

                                                            if (!('ticket' in respuestaAnulacionSunat)) {
                                                                return null;
                                                            }

                                                            if ('consultaTicket' in respuestaAnulacionSunat) {
                                                                if (respuestaAnulacionSunat.consultaTicket.cdrZip) {
                                                                    return <div className="dropdown-item px-2">
                                                                        <a
                                                                            className="btn btn-success btn-sm btn-block font-weight-bold"
                                                                            target="blank"
                                                                            href={respuestaAnulacionSunat.consultaTicket.cdrZip.replace('http', 'https')}
                                                                            title="Descargar CDR"
                                                                        >
                                                                            Descargar CDR
                                                                        </a>
                                                                    </div>
                                                                }
                                                            }

                                                            return <div className="dropdown-item px-2">
                                                                <button
                                                                    className="btn btn-warning btn-sm btn-block font-weight-bold"
                                                                    title="Consultar ticket"
                                                                    onClick={() => props.handleQueryTicketBaja(f.original.IdRegistroVenta, respuestaAnulacionSunat)}
                                                                >
                                                                    Consultar ticket
                                                                </button>
                                                            </div>})()
                                                        : null}


                                                    </div>
                                                </div>
                                            </>
                                        );
                                    }
                                },
                                {
                                    Header: "EstadoCE",
                                    id: "EstadoCE",
                                    accessor: d => d.EstadoCE,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["EstadoCE"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Nro Comprobante",
                                    id: "NumeroComprobante",
                                    accessor: d => d.NumeroComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["NumeroComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Serie",
                                    id: "Serie",
                                    accessor: d => d.Serie,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Serie"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Cliente",
                                    id: "RazonSocial",
                                    accessor: d => d.RazonSocial,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Fecha",
                                    id: "FechaEmision",
                                    accessor: d => d.FechaEmision,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["FechaEmision"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "RUC",
                                    id: "Ruc",
                                    accessor: d => d.NroTipoDocumento,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {keys: ["NroTipoDocumento"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Tipo Comprobante",
                                    id: "TipoComprobante",
                                    accessor: d => d.TipoComprobante,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["TipoComprobante"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Desc. Items.",
                                    id: "descItems",
                                    accessor: d => decimalAdjust('floor', d.descItems, -2),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["descItems"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Total",
                                    id: "Total",
                                    accessor: d => d.Total.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) ? d.Total.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) : 0.0,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Total"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Redondeo",
                                    id: "Redondeo",
                                    accessor: d => d.Redondeo == null ? '0.00' : d.Redondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Redondeo"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "TotalRedondeo",
                                    id: "TotalRedondeo",
                                    accessor: d => d.TotalRedondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) ? d.TotalRedondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) : 0.0,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["TotalRedondeo"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Gravadas",
                                    id: "Gravadas",
                                    accessor: d => d.Gravadas.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Gravadas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Exoneradas",
                                    id: "Exoneradas",
                                    accessor: d => d.Exoneradas.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Exoneradas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "Inafectas",
                                    id: "Inafectas",
                                    accessor: d => d.Inafectas.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["Inafectas"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "IGV",
                                    id: "IGV",
                                    accessor: d => d.IGV.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["IGV"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                                {
                                    Header: "ICBPER",
                                    id: "ICBPER",
                                    accessor: d => d.ICBPER.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, {
                                            keys: ["ICBPER"]
                                        }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background:
                                                    rowInfo && rowInfo.row.Estado === "ANULADA"
                                                        ? "#ffcccc"
                                                        : null
                                            }
                                        };
                                    }
                                },
                            ]
                        },
                    ]}
                    manual={true}
                    filterable={true}
                    sortable={true}
                    pages={table.pages}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Siguiente"
                    noDataText="Lista vacía"
                    loadingText="Cargando..."
                    ofText="de"
                    rowsText="filas"
                    pageText="Página"
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    SubComponent={row => {
                        return (
                            <>
                                <div
                                    className={row.original.CodigoCE === "0" ? "alert alert-success p-3" : "alert alert-danger p-3"}
                                    width="70%">
                                    {row.original.EstadoCE ? `Código: ${row.original.CodigoCE || "-1"} :::::: Descripción: ${row.original.DescripcionCE || ""} ` : ``}
                                </div>
                                <a href="#" onClick={e => {
                                    e.preventDefault();
                                    props.onDownloadPdf(row.original.IdRegistroVenta, false)
                                }}>Descargar PDF</a>
                            </>
                        )
                    }}
                />
            </div>
            <Modal isOpen={props.showModalValidacion} onClose={props.handleCloseModalValidarAnular} width='900px'
                   title="Validar Anulación de Comprobante">
                <label className="font-weight-bold" htmlFor="inputValidacion">
                    Codigo
                </label>
                <input type="password"
                       name="inputValidacion"
                       value={(props.codigoValidacionInput)}
                       className="form-control mb-2"
                       onChange={props.handleChangeCodigoAnular}
                       onKeyDown={props.onValidarCodigoKeyDown}/>

                <div className="d-flex justify-content-end">
                <button
                    className="btn btn-verde-mfp-letra-normal"
                    title="Validar"
                    onClick={() => {props.validarCodigoUsuario()}}
                >Validar</button>
                </div>
            </Modal>
        </section>
    );
};

export default RegistrosVentasUI;
