import React from 'react'
import {
    Page,
    Text,
    View,
    Document,
    Image

} from "@react-pdf/renderer";
import {numberFormat} from "../../helpers/funcionesGlobales";


const FIRST_PAGE_MAX_ITEMS = 24;
const OTHER_PAGE_MAX_ITEMS = 32;


function divideArray(inputArray) {
    let index = 0;
    let subArrays = [];

    if (inputArray.length > 0) {
        subArrays.push(inputArray.slice(index, index + FIRST_PAGE_MAX_ITEMS));
        index += FIRST_PAGE_MAX_ITEMS;
    }

    while (index < inputArray.length) {
        subArrays.push(inputArray.slice(index, index + OTHER_PAGE_MAX_ITEMS));
        index += OTHER_PAGE_MAX_ITEMS;
    }

    return subArrays;
}


export default function defaultTemplate(Estilos, props, {logoUrl, relDocs}) {
    const tipo = props.cabnc.Tipo === "ND" ? "Nota de Débito" : "Nota de Crédito"
    const moneda = props.cabnc.SimboloMoneda;

    // const detalles = [...props.detnc,...props.detnc,...props.detnc,...props.detnc];
    const detalles = props.detnc;
    console.log(detalles.length)    

    if (detalles.length > FIRST_PAGE_MAX_ITEMS) {
        const details = divideArray(detalles);

        const [first, ...rest] = details;

        return <Document>
            <Page style={Estilos.page}>
                <View style={Estilos.header}>
                    <View style={Estilos.emp_logo}>
                        <Image
                            source={logoUrl}/>
                    </View>
                    <View style={Estilos.emp_info}>
                        <Text
                            style={Estilos.emp_info_title}>{`${tipo} N° ${props.cabnc.SerieCD}-${props.cabnc.NumeroCD}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Principal:  ${props.cabnc.NombreEmpresa} :: ${props.cabnc.DireccionEmpresa}`}</Text>
                        <Text
                            style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.cabnc.NombreSucursal} :: ${props.cabnc.DireccionSucursal}`}</Text>
                    </View>
                </View>
                <View style={Estilos.body}>
                    <View style={Estilos.body_prov}>
                        <View style={Estilos.body_prov_info}>
                            <View style={Estilos.body_prov_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Fecha de Emisión:</Text>
                                <Text style={Estilos.cell}>{props.cabnc.FechaEmision}</Text>
                                <Text style={Estilos.cell_etiqueta}>Tipo {tipo}:</Text>
                                <Text style={Estilos.cell}>{props.cabnc.DescripcionTipoNCD}</Text>
                            </View>
                            <View style={Estilos.body_prov_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>Motivo o Sustento:</Text>
                                <Text style={Estilos.cell}>{props.cabnc.Descripcion || '-'}</Text>
                            </View>
                        </View>
                        <View style={Estilos.body_prov_oc}>
                            <Text style={[Estilos.cell, {alignContent: 'center', fontSize: 11}]}>{`RUC`}</Text>
                            <Text style={[Estilos.cell, {
                                alignContent: 'center',
                                fontSize: 11
                            }]}>{`${props.cabnc.RucEmpresa}`}</Text>
                            <Text style={[Estilos.cell, {alignContent: 'center'}]}>{`${tipo}:`}</Text>
                            <Text
                                style={[Estilos.cell, {alignContent: 'center'}]}>{`${props.cabnc.SerieCD}-${props.cabnc.NumeroCD}`}</Text>
                        </View>
                    </View>
                    <View style={{marginTop: 5}}><Text>Documento que modifica:</Text></View>
                    <View style={Estilos.body_oc}>
                        <View style={Estilos.body_oc_info}>
                            <View style={Estilos.body_oc_info_c1}>
                                <Text style={Estilos.cell_etiqueta}>Nro Comprobante Electrónico:</Text>
                                <Text
                                    style={Estilos.cell}>{`${props.cabnc.SerieCE}-${Number(props.cabnc.NumeroCE)} ${relDocs.length ? ` con ${relDocs[0].numDoc}` : ""}`}</Text>
                                <Text style={Estilos.cell_etiqueta}>Fecha Emisión:</Text>
                                <Text style={Estilos.cell}>{`${props.cabnc.FechaEmisionRV}`}</Text>
                            </View>
                            <View style={Estilos.body_oc_info_c2}>
                                <Text style={Estilos.cell_etiqueta}>RUC / DNI:</Text>
                                <Text style={Estilos.cell}>{props.cabnc.RucCliente}</Text>
                                <Text style={Estilos.cell_etiqueta}>Señor(es):</Text>
                                <Text style={Estilos.cell}>{props.cabnc.NombreCliente}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{marginTop: 5}}><Text>Detalle de la {tipo}</Text></View>
                    <View style={Estilos.body_detalle}>
                        <View style={Estilos.table}>
                            <View style={Estilos.tableRow}>
                                <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>Item</Text>
                                </View>
                                {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                    <Text style={Estilos.tableCellHeader}>Código</Text>
                                    </View> */}
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                    <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                </View>
                                {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                    <Text style={Estilos.tableCellHeader}>UM</Text>
                                    </View> */}
                                <View style={[Estilos.tableColHeader, {width: "62%", textAlign: "left"}]}>
                                    <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Precio ${moneda}`}</Text>
                                </View>
                                <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                    <Text style={Estilos.tableCellHeader}>{`Total  ${moneda}`}</Text>
                                </View>
                            </View>
                            {
                                first.map((item) => {
                                    return (
                                        <View key={item.Correlativo} style={Estilos.tableRow}>
                                            <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                            </View>
                                            {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                            <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.Codigo}</Text>
                                            </View> */}
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{numberFormat(item.Cantidad, 2, 3, ',', '.')}</Text>
                                            </View>
                                            {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                            <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.NomUnidad}</Text>
                                            </View> */}
                                            <View style={[Estilos.tableCol, {width: "62%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "left"}]}>{item.Descripcion}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{numberFormat(item.PrecioConIGV, 2, 3, ',', '.')}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{numberFormat(item.Cantidad * item.PrecioConIGV, 2, 3, ',', '.')}</Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                </View>

                {/* <View style={Estilos.footer} fixed>
                    <View style={Estilos.footer_seccion_1}>
                        <Text style={Estilos.cell_etiqueta}>Observaciones:</Text>
                        <Text  style={Estilos.cell}>{props.cabnc.DescripciónTipoNCD}</Text>
                    </View>
                    <View style={Estilos.footer_seccion_2}>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Gestor de Compras:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Aprobado Por:</Text></View>
                        <View style={Estilos.footer_seccion_2_seccion}><Text>Área Almacén:</Text></View>
                    </View>
                </View> */}

                <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed/>
            </Page>

            {
                rest.map((d, index) => {
                    return <Page size="A4" style={Estilos.page}>
                        <View style={Estilos.body_detalle}>
                            <View style={Estilos.table}>
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                        <Text style={Estilos.tableCellHeader}>Item</Text>
                                    </View>
                                    {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                        <Text style={Estilos.tableCellHeader}>Código</Text>
                                        </View> */}
                                    <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                        <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                                    </View>
                                    {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                        <Text style={Estilos.tableCellHeader}>UM</Text>
                                        </View> */}
                                    <View style={[Estilos.tableColHeader, {width: "62%", textAlign: "left"}]}>
                                        <Text style={Estilos.tableCellHeader}>Descripción</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                        <Text style={Estilos.tableCellHeader}>{`Precio ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text style={Estilos.tableCellHeader}>{`Total  ${moneda}`}</Text>
                                    </View>
                                </View>
                            {
                                d.map((item) => {
                                    return (
                                        <View key={item.Correlativo} style={Estilos.tableRow}>
                                            <View style={[Estilos.tableCol, {width: "6%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                            </View>
                                            {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                            <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.Codigo}</Text>
                                            </View> */}
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "center"}]}>{numberFormat(item.Cantidad, 2, 3, ',', '.')}</Text>
                                            </View>
                                            {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                            <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.NomUnidad}</Text>
                                            </View> */}
                                            <View style={[Estilos.tableCol, {width: "62%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "left"}]}>{item.Descripcion}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "10%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{numberFormat(item.PrecioConIGV, 2, 3, ',', '.')}</Text>
                                            </View>
                                            <View style={[Estilos.tableCol, {width: "12%"}]}>
                                                <Text
                                                    style={[Estilos.tableCell, {textAlign: "right"}]}>{numberFormat(item.Cantidad * item.PrecioConIGV, 2, 3, ',', '.')}</Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }

                            {
                                index == rest.length - 1 && <>
                                {props.cabnc.Gravadas != 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`Gravado ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.Gravadas, 2, 3, ',', '.')}</Text>
                                    </View>
                                </View>
                                }
                                {props.cabnc.IGV != 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`IGV ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.IGV, 2, 3, ',', '.')}</Text>
                                    </View>
                                </View>
                                }
                                {props.cabnc.Exoneradas != 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`Exonerado ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.Exoneradas, 2, 3, ',', '.')}</Text>
                                    </View>
                                </View>
                                }
                                {props.cabnc.ISC != 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`ISC ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.ISC, 2, 3, ',', '.')}</Text>
                                    </View>
                                </View>
                                }
                                {props.cabnc.ICBPER != 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {
                                        width: "88%",
                                        textAlign: "right",
                                        borderBottom: 0
                                    }]}>
                                        <Text style={Estilos.tableCellHeader}>{`ICBPER ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.ICBPER, 2, 3, ',', '.')}</Text>
                                    </View>
                                </View>
                                }
                                {props.cabnc.Total != 0 &&
                                <View style={Estilos.tableRow}>
                                    <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                        <Text style={Estilos.tableCellHeader}>{`Total ${moneda}`}</Text>
                                    </View>
                                    <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                        <Text
                                            style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.Total, 2, 3, ',', '.')}</Text>
                                    </View>
                                </View>
                                }
                                </>
                            }
                            </View>
                        </View>
                        <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed/>
                    </Page>
                })
            }
        </Document>
    }

    return <Document>
        <Page style={Estilos.page}>
            <View style={Estilos.header}>
                <View style={Estilos.emp_logo}>
                    <Image
                        source={logoUrl}/>
                </View>
                <View style={Estilos.emp_info}>
                    <Text
                        style={Estilos.emp_info_title}>{`${tipo} N° ${props.cabnc.SerieCD}-${props.cabnc.NumeroCD}`}</Text>
                    <Text
                        style={Estilos.emp_info_detalle}>{`Principal:  ${props.cabnc.NombreEmpresa} :: ${props.cabnc.DireccionEmpresa}`}</Text>
                    <Text
                        style={Estilos.emp_info_detalle}>{`Sucursal:  ${props.cabnc.NombreSucursal} :: ${props.cabnc.DireccionSucursal}`}</Text>
                </View>
            </View>
            <View style={Estilos.body}>
                <View style={Estilos.body_prov}>
                    <View style={Estilos.body_prov_info}>
                        <View style={Estilos.body_prov_info_c1}>
                            <Text style={Estilos.cell_etiqueta}>Fecha de Emisión:</Text>
                            <Text style={Estilos.cell}>{props.cabnc.FechaEmision}</Text>
                            <Text style={Estilos.cell_etiqueta}>Tipo {tipo}:</Text>
                            <Text style={Estilos.cell}>{props.cabnc.DescripcionTipoNCD}</Text>
                        </View>
                        <View style={Estilos.body_prov_info_c2}>
                            <Text style={Estilos.cell_etiqueta}>Motivo o Sustento:</Text>
                            <Text style={Estilos.cell}>{props.cabnc.Descripcion || '-'}</Text>
                        </View>
                    </View>
                    <View style={Estilos.body_prov_oc}>
                        <Text style={[Estilos.cell, {alignContent: 'center', fontSize: 11}]}>{`RUC`}</Text>
                        <Text style={[Estilos.cell, {
                            alignContent: 'center',
                            fontSize: 11
                        }]}>{`${props.cabnc.RucEmpresa}`}</Text>
                        <Text style={[Estilos.cell, {alignContent: 'center'}]}>{`${tipo}:`}</Text>
                        <Text
                            style={[Estilos.cell, {alignContent: 'center'}]}>{`${props.cabnc.SerieCD}-${props.cabnc.NumeroCD}`}</Text>
                    </View>
                </View>
                <View style={{marginTop: 5}}><Text>Documento que modifica:</Text></View>
                <View style={Estilos.body_oc}>
                    <View style={Estilos.body_oc_info}>
                        <View style={Estilos.body_oc_info_c1}>
                            <Text style={Estilos.cell_etiqueta}>Nro Comprobante Electrónico:</Text>
                            <Text
                                style={Estilos.cell}>{`${props.cabnc.SerieCE}-${Number(props.cabnc.NumeroCE)} ${relDocs.length ? ` con ${relDocs[0].numDoc}` : ""}`}</Text>
                            <Text style={Estilos.cell_etiqueta}>Fecha Emisión:</Text>
                            <Text style={Estilos.cell}>{`${props.cabnc.FechaEmisionRV}`}</Text>
                        </View>
                        <View style={Estilos.body_oc_info_c2}>
                            <Text style={Estilos.cell_etiqueta}>RUC / DNI:</Text>
                            <Text style={Estilos.cell}>{props.cabnc.RucCliente}</Text>
                            <Text style={Estilos.cell_etiqueta}>Señor(es):</Text>
                            <Text style={Estilos.cell}>{props.cabnc.NombreCliente}</Text>
                        </View>
                    </View>
                </View>
                <View style={{marginTop: 5}}><Text>Detalle de la {tipo}</Text></View>
                <View style={Estilos.body_detalle}>
                    <View style={Estilos.table}>
                        <View style={Estilos.tableRow}>
                            <View style={[Estilos.tableColHeader, {width: "6%", textAlign: "center"}]}>
                                <Text style={Estilos.tableCellHeader}>Item</Text>
                            </View>
                            {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                  <Text style={Estilos.tableCellHeader}>Código</Text>
                                </View> */}
                            <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "center"}]}>
                                <Text style={Estilos.tableCellHeader}>Cantidad</Text>
                            </View>
                            {/* <View style={[Estilos.tableColHeader,{width:"10%", textAlign:"center"}]}>
                                  <Text style={Estilos.tableCellHeader}>UM</Text>
                                </View> */}
                            <View style={[Estilos.tableColHeader, {width: "62%", textAlign: "left"}]}>
                                <Text style={Estilos.tableCellHeader}>Descripción</Text>
                            </View>
                            <View style={[Estilos.tableColHeader, {width: "10%", textAlign: "right"}]}>
                                <Text style={Estilos.tableCellHeader}>{`Precio ${moneda}`}</Text>
                            </View>
                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                <Text style={Estilos.tableCellHeader}>{`Total  ${moneda}`}</Text>
                            </View>
                        </View>
                        {
                            props.detnc.map((item) => {
                                return (
                                    <View key={item.Correlativo} style={Estilos.tableRow}>
                                        <View style={[Estilos.tableCol, {width: "6%"}]}>
                                            <Text
                                                style={[Estilos.tableCell, {textAlign: "center"}]}>{item.Correlativo}</Text>
                                        </View>
                                        {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                          <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.Codigo}</Text>
                                        </View> */}
                                        <View style={[Estilos.tableCol, {width: "10%"}]}>
                                            <Text
                                                style={[Estilos.tableCell, {textAlign: "center"}]}>{numberFormat(item.Cantidad, 2, 3, ',', '.')}</Text>
                                        </View>
                                        {/* <View style={[Estilos.tableCol, {width:"10%"}]}>
                                          <Text style={[Estilos.tableCell, {textAlign:"center"}]}>{item.NomUnidad}</Text>
                                        </View> */}
                                        <View style={[Estilos.tableCol, {width: "62%"}]}>
                                            <Text
                                                style={[Estilos.tableCell, {textAlign: "left"}]}>{item.Descripcion}</Text>
                                        </View>
                                        <View style={[Estilos.tableCol, {width: "10%"}]}>
                                            <Text
                                                style={[Estilos.tableCell, {textAlign: "right"}]}>{numberFormat(item.PrecioConIGV, 2, 3, ',', '.')}</Text>
                                        </View>
                                        <View style={[Estilos.tableCol, {width: "12%"}]}>
                                            <Text
                                                style={[Estilos.tableCell, {textAlign: "right"}]}>{numberFormat(item.Cantidad * item.PrecioConIGV, 2, 3, ',', '.')}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        {props.cabnc.Gravadas != 0 &&
                        <View style={Estilos.tableRow}>
                            <View style={[Estilos.tableColHeader, {
                                width: "88%",
                                textAlign: "right",
                                borderBottom: 0
                            }]}>
                                <Text style={Estilos.tableCellHeader}>{`Gravado ${moneda}`}</Text>
                            </View>
                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                <Text
                                    style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.Gravadas, 2, 3, ',', '.')}</Text>
                            </View>
                        </View>
                        }
                        {props.cabnc.IGV != 0 &&
                        <View style={Estilos.tableRow}>
                            <View style={[Estilos.tableColHeader, {
                                width: "88%",
                                textAlign: "right",
                                borderBottom: 0
                            }]}>
                                <Text style={Estilos.tableCellHeader}>{`IGV ${moneda}`}</Text>
                            </View>
                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                <Text
                                    style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.IGV, 2, 3, ',', '.')}</Text>
                            </View>
                        </View>
                        }
                        {props.cabnc.Exoneradas != 0 &&
                        <View style={Estilos.tableRow}>
                            <View style={[Estilos.tableColHeader, {
                                width: "88%",
                                textAlign: "right",
                                borderBottom: 0
                            }]}>
                                <Text style={Estilos.tableCellHeader}>{`Exonerado ${moneda}`}</Text>
                            </View>
                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                <Text
                                    style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.Exoneradas, 2, 3, ',', '.')}</Text>
                            </View>
                        </View>
                        }
                        {props.cabnc.ISC != 0 &&
                        <View style={Estilos.tableRow}>
                            <View style={[Estilos.tableColHeader, {
                                width: "88%",
                                textAlign: "right",
                                borderBottom: 0
                            }]}>
                                <Text style={Estilos.tableCellHeader}>{`ISC ${moneda}`}</Text>
                            </View>
                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                <Text
                                    style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.ISC, 2, 3, ',', '.')}</Text>
                            </View>
                        </View>
                        }
                        {props.cabnc.ICBPER != 0 &&
                        <View style={Estilos.tableRow}>
                            <View style={[Estilos.tableColHeader, {
                                width: "88%",
                                textAlign: "right",
                                borderBottom: 0
                            }]}>
                                <Text style={Estilos.tableCellHeader}>{`ICBPER ${moneda}`}</Text>
                            </View>
                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                <Text
                                    style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.ICBPER, 2, 3, ',', '.')}</Text>
                            </View>
                        </View>
                        }
                        {props.cabnc.Total != 0 &&
                        <View style={Estilos.tableRow}>
                            <View style={[Estilos.tableColHeader, {width: "88%", textAlign: "right"}]}>
                                <Text style={Estilos.tableCellHeader}>{`Total ${moneda}`}</Text>
                            </View>
                            <View style={[Estilos.tableColHeader, {width: "12%", textAlign: "right"}]}>
                                <Text
                                    style={Estilos.tableCellHeader}>{numberFormat(props.cabnc.Total, 2, 3, ',', '.')}</Text>
                            </View>
                        </View>
                        }

                    </View>
                </View>
            </View>

            <Text style={Estilos.pageNumber} render={({pageNumber, totalPages}) => (
                `${pageNumber} / ${totalPages}`
            )} fixed/>
        </Page>

    </Document>

}
