import React from 'react';
import PageLoading from '../PageLoading';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import Modal from '../clientes/ClienteModal';
import MovimientoAlmacenEditar from './MovimientoAlmacenEditar';
import {
    BotonEditar,
    BotonDelete,
    BotonAdd,
    BotonProcesarPequeno,
    BotonPDFpequeno,
    BotonTicket
} from '../Plantillas/Botones';
import swal from "sweetalert";
import ModalPDF from '../Compras/Reportes/modalPDF';
import VisorPDF from '../Compras/Reportes/visorPDF'
import ReporteMA from './Reportes/RptMA'
import "../Plantillas/Estilos.css"
import Notify from "../Notify";
import moment from "moment";
import ReactExport from "react-data-export";
import CreditForm from "./CreditForm";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import { printTicket } from './Ticket';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class MovimientoAlmacenLista extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            buscar: "",
            modalIsOpen: false,
            modalIsOpenPDF: false,
            accion: '',
            nroMA_Seleccionado: '',
            loading: true,
            error: null,
            dataLista: [],
            itemmovimientoalmacen: {
                idmovimientoalmacen: null,
                nroserie: '',
            },
            itemproveedor: {
                nomproveedor: '',
                idproveedor: null,
            },
            cabma: {},
            detma: [],
            fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            fechaFin: moment(new Date()).format('YYYY-MM-DD'),
            dataExcel: this.getDataMovsExportExcel([]),
            totalConComprobante: 0,
            totalSinComprobante: 0,
            sucursales: [],
            almacen: {},
            idSucursal: 0,
            IdAlmacen: 0,
            idMovAlmacen: 0,
            FiltrarSucursal: false,
            user: {},
            rucUsuario:"",
            extImg: 'png',
            showModalProcesar: false,
            selectedMov: null,
            turnoCaja: null,
            modalApertura: false,
            permisos: {
                canUpdate: false,
                canProcess: false,
                canDelete: false,
            }
        }
        this.onFechaInicioChange = this.onFechaInicioChange.bind(this);
        this.onFechaFinChange = this.onFechaFinChange.bind(this);
        this.loadMovsAlmacenHandler = this.loadMovsAlmacenHandler.bind(this);
        this.onChangeSucursal = this.onChangeSucursal.bind(this);
        this.fetchTurnoCaja = this.fetchTurnoCaja.bind(this);
        this.getTurno = this.getTurno.bind(this);
        this.fetchPermissions = this.fetchPermissions.bind(this);
    }

    componentDidMount() {
        this.fetchPermissions();
        this.inicio();
    }

    inicio = async () => {
        this.DatosUser();
        this.fetchTurnoCaja();
        // await this.getSucursales();
    }

    async fetchPermissions() {
        try {
            const response = await fetch(`/api/movimientosalmacen/permisos`);
            const data = await response.json();
            this.setState({ permisos: data })
        } catch (e) {
            console.error(e)
        }
    }

    async fetchTurnoCaja() {
        let permiso = false;

        try {
            const response = await fetch(`/api/cajas/user/validar`);
            const data = await response.json();
            permiso = Boolean(data.ValidarUser)
        } catch (e) {
            console.error(e)
        }

        if (!permiso) {
            this.setState({
                puedeRegistrar: false
            })
            return;
        }

        const turnoInfo = await this.getTurno();

        if (!turnoInfo.Turno) {
            this.setState({
                puedeRegistrar: false
            })
            notificarMsg("Se ha detectado que la caja se encuentra cerrada", "warning")
            return
        }

        const turno = turnoInfo.Turno[0][0];

        const fechaInicio = moment(turno.FechaInicio).add(-5, 'hours').format("YYYY-MM-DD")
        const now = moment().format("YYYY-MM-DD");

        this.setState({
            turnoCaja: turno,
            puedeRegistrar: fechaInicio == now,
            modalApertura: !(fechaInicio == now)
        })
    }

    async getTurno() {
        try {
            const response = await fetch(`/api/cajas/verificar/Turno`);
            const dataTurno = await response.json();
            return dataTurno
        } catch (e) {
            return {Turno: null}
        }

    }

    async DatosUser() {
        try {
            const response = await fetch(`/api/configFactElectronica/User`);
            const data = await response.json();
            console.log({data})
            this.setState({
                idSucursal: data.objuser.IdSucursal,
                IdAlmacen: data.objuser.IdSucursal,
                user: data.objuser,
                extImg: data.extImg,
                rucUsuario: data.objuser.Ruc
            })
            await this.ValidarRol()
        } catch (error) {
        }
    }

    async ValidarRol() {
        try {
            const response = await fetch(`/api/configFactElectronica/ChangeSucursal`);
            const data = await response.json();
            this.setState({
                FiltrarSucursal: true
            })
        } catch (error) {
            this.setState({
                FiltrarSucursal: false
            })
        }
        await this.getSucursales();
    }

    handleChangeAlmacenes = async (e) => {
        this.setState({
            IdAlmacen: e.target.value,
        });
        this.listaMovimientosAlmacenPorAlmacen(e.target.value);
    };

    handleClickNuevo = async (e) => {
        window.sessionStorage.removeItem('idMovAlmacen');
        this.setState({
            modalIsOpen: true,
            swPrincipal: 1,
            accion: 'Nuevo',
        })
    }

    cambiarAccion = (a) => {
        this.setState({
            accion: a
        })
    }

    handleClickEditar = async (reg) => {
        this.setState({
            modalIsOpen: true,
            swPrincipal: 1,
            accion: 'Editar',
            itemmovimientoalmacen: {
                idmovimientoalmacen: reg.IdMovimientoAlmacen,
                nroserie: reg.Numero,
            },
            idMovAlmacen: reg.IdMovimientoAlmacen
        })
    }

    crearOrdenCompra = async () => {
        try {
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdOrdenCompra: '1',
                    FechaRegistro: null,
                    FechaEmision: null,
                    FechaEntrega: null,
                    Serie: null,
                    Numero: null,
                    IdMoneda: null,
                    TipoCambio: null,
                    IdUsuario: null,
                    IdSucursal: '1',
                    Estado: 'Por Procesar',
                    IdAlmacen: null,
                    Glosa: null,
                    IdProveedor: null,
                })
            };
            const response = await fetch(`/api/ordenescompra/add/`, config);
            const rpta = await response.json();
            this.setState({
                itemordencompra: {
                    idordencompra: rpta.respuesta
                },
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    listaMovimientosAlmacenPorAlmacen = async (id) => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/movimientosalmacen/list/${id || "0"}/${this.state.fechaInicio}/${this.state.fechaFin}`);
            const data = await response.json();
            let res = data[1];
            this.setState({
                loading: false,
                dataLista: [1, res.movs],
                dataExcel: this.getDataMovsExportExcel(res.movs),
                totalConComprobante: Number(res.totalConComprobante),
                totalSinComprobante: Number(res.totalSinComprobante)
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    handleInput = (e) => {
        this.setState({
            buscar: e.target.value,
        });
    }

    ClickAnularMA = (reg) => {
        swal({
            title: 'Cuidado...! ',
            text: `Desea Anular el registro Nro: ${reg.Numero}?`,
            icon: 'error',
            buttons: [
                'No',
                'Si'
            ],
        }).then((isConfirm) => {
            if (isConfirm) {
                this.anularMA(reg);
            }
        })
    }

    anularMA = async (reg) => {
        try {
            const config = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdMovimientoAlmacen: reg.IdMovimientoAlmacen,
                    Estado: 'Anulado',
                })
            };
            const res = await fetch(`/api/movimientosalmacen/actualizarestado/`, config);
            const rpta = await res.json();

            if (rpta.respuesta === 'Ok') {
                await this.listaMovimientosAlmacenPorAlmacen(this.state.IdAlmacen);
                notificarMsg("Se Anuló correctamente...")
            } else {
                notificarError("Error al Anular el Movimiento de Almacén...")
            }

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    ClickProcesarMA = (reg) => {
        this.setState({selectedMov: reg, showModalProcesar: true})
    }

    ProcesarMA = async (reg) => {
        try {
            const config = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdMovimientoAlmacen: reg.IdMovimientoAlmacen,
                    IdTipoStock: parseInt(reg.IdTipoDocumentoSunat) === 1 ? 2 : 1,
                    IdTipoMovimientoAlmacen: reg.IdTipoMovimientoAlmacen,
                    IdAlmacen: reg.IdAlmacen
                })
            };
            const res = await fetch(`/api/movimientosalmacen/actualizarstocks/`, config);
            const rpta = await res.json();

            if (rpta[0][0][0].estado === 'Actualizado') // actualizó correctamente
            {
                await this.listaMovimientosAlmacenPorAlmacen(this.state.IdAlmacen);
                notificarMsg("Se procesó correctamente...")
                this.setState({showModalProcesar: false})
            } else {
                notificarError("Error al Procesar el Movimiento de Almacèn...")
            }

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    handleOpenModal = (e) => {
        this.setState({
            modalIsOpen: true,
            accion: 'Nuevo',
            itemproveedor: {
                ...this.state.itemproveedor,
                nomproveedor: e.target.value,
                idproveedor: e.target.id,
            }
        })
    }

    handleCloseModal = (e) => {
        window.sessionStorage.removeItem('idMovAlmacen');
        this.setState({
            modalIsOpen: false,
            showModalProcesar: false
        })
    }

    handleCloseModalPDF = (e) => {
        window.sessionStorage.removeItem('idMovAlmacen');
        this.setState({
            modalIsOpenPDF: false
        })
    }

    handleOpenModalPDF = async (reg) => {
        await this.cabeceraMA(reg.IdMovimientoAlmacen)
        await this.detalleMA(reg.IdMovimientoAlmacen)
        this.setState({
            modalIsOpenPDF: true,
            nroMA_Seleccionado: reg.Numero,
            itemmovimientoalmacen: {
                idmovimientoalmacen: reg.IdMovimientoAlmacen,
                nroserie: reg.Numero,
            },
        })
    }

    handleDownloadTicket = async (reg) => {
        await this.cabeceraMA(reg.IdMovimientoAlmacen);
        await this.detalleMA(reg.IdMovimientoAlmacen);

        printTicket(this.state.detma, this.state.cabma, this.state.extImg);
    }

    acciones(f) {
        return (
            <div align="center">
                <div className='mr-1'>
                    <button id={f.value} value={f.original.RazonSocial} onClick={this.handleOpenModal}
                            className="btn btn-outline-primary fas fa-eye"
                            title="Ver"/>

                </div>
                <div className='mr-1'>
                    <button id={f.value} value={f.original.RazonSocial} onClick={this.handleOpenModal}
                            className="btn btn-outline-danger fas fa-eye"
                            title="Anular"
                    />
                </div>
            </div>
        )
    }

    cabeceraMA = async (idma) => {
        try {
            const response = await fetch(`/api/movimientosalmacen/item/${idma}`);
            const regma = await response.json();
            this.setState({
                cabma: regma[1][0]
            })
        } catch (error) {
            console.error(error);
        }
    }

    detalleMA = async (idma) => {
        try {
            const response = await fetch(`/api/movimientosalmacen/detallema/${idma}`);
            const data = await response.json();
            this.setState({
                detma: data[1]
            })

        } catch (error) {
            console.error(error);
            return null
        }
    }

    getDataMovsExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: "Estado",
                        width: {wpx: 100}
                    },
                    {
                        title: "Tipo",
                        width: {wpx: 100}
                    },
                    {
                        title: "Código",
                        width: {wpx: 100}
                    },
                    {
                        title: "Fecha de movimiento",
                        width: {wpx: 100}
                    },
                    {
                        title: "Número Doc.",
                        width: {wpx: 100}
                    },
                    {
                        title: "Tipo de Doc.",
                        width: {wpx: 100}
                    },
                    {
                        title: "Proveedor",
                        width: {wpx: 100}
                    },
                    {
                        title: "Total Inafectas",
                        width: {wpx: 100}
                    },
                    {
                        title: "Total Exoneradas",
                        width: {wpx: 100}
                    },
                    {
                        title: "Total",
                        width: {wpx: 100}
                    },
                    {
                        title: "Transacción",
                        width: {wpx: 150}
                    },
                    {
                        title: "Descripción",
                        width: {wpx: 300}
                    }
                ],

                data: dataSet.map(row => {
                    return [
                        {
                            value: row.Estado,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NomTipoMovimiento || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Numero || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.FechaMovimiento || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NroDocumentoSunat || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.DescTipoDocSunat || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.RazonSocial || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.totalInafectos,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.totalExoneradas,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Total,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.NomTipoTransaccion || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Glosa || "",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ];
    }

    onFechaInicioChange(e) {
        const fechaInicio = e.target.value;
        this.setState({fechaInicio});
    }

    onFechaFinChange(e) {
        this.setState({
            fechaFin: e.target.value
        })
    }

    loadMovsAlmacenHandler(e) {
        let idAlmacen = Object.keys(this.state.almacen).length ? this.state.almacen.IdSucursal : this.state.IdAlmacen;
        const fechasCorrectas = this.state.fechaInicio.length && this.state.fechaFin.length && idAlmacen;
        if (!fechasCorrectas && !moment(this.state.fechaInicio).isAfter(this.state.fechaFin))
            Notify("Las fechas ingresadas son incorrectas", 'error')
        else
            this.listaMovimientosAlmacenPorAlmacen(idAlmacen);
    }

    async getSucursales() {
        const response = await fetch("/api/configFactElectronica/SucursalEmpresa");
        const data = await response.json();
        let idSucursal = this.state.idSucursal;
        let almacen = await this.getAlmacen(idSucursal)
        await this.listaMovimientosAlmacenPorAlmacen(almacen.IdAlmacen);
        this.setState({
            sucursales: data,
            almacen: almacen,
            loading: false,
            IdAlmacen: almacen.IdAlmacen
        })
    }

    async getAlmacen(idSucursal) {
        const response = await fetch(`/api/almacenes/AlmacenBySucursal?idSucursal=${idSucursal}`);
        const data = await response.json();
        return data.results[0];
    }

    async onChangeSucursal(e) {
        let idSucursal = e.target.value;
        let almacen = await this.getAlmacen(idSucursal);
        this.setState({almacen: almacen, idSucursal: idSucursal, IdAlmacen: almacen.IdAlmacen})
    }

    render() {
        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }

        if (this.state.loading)
            return <PageLoading/>;
        const styleCalendar = {
            border: "0.5px solid #acacac",
            borderRadius: "25px",
            outline: "none",
            flexBasis: "50%",
            alignSelf: "center"
        };
        return (
            <div className='ventas-fpay-section'>
                <div className="pt-3">
                    {/* <div className='justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2 no-marg'>
                    <div className="col-sm-auto mt-1 ">
                        Rango de fechas
                    </div>
                    <div className="col-sm-auto">
                        <input onChange={this.onFechaInicioChange} type="date" name="fechaInicio"
                            className="form-control" value={this.state.fechaInicio} />
                    </div>
                    <div className="col-sm-auto">
                        <input onChange={this.onFechaFinChange} type="date" name="fechaFin" className="form-control"
                            value={this.state.fechaFin} />
                    </div>
                </div> */}
                    <div
                        className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2 mt-3"
                    >
                        <span className="align-self-center letra-fecha-fpay">Desde: </span>
                        <input
                            type="date"
                            onChange={this.onFechaInicioChange}
                            defaultValue={this.state.fechaInicio}
                            value={this.state.fechaInicio}
                            className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                            style={styleCalendar}
                        />
                        <span className="align-self-center letra-fecha-fpay">Hasta: </span>
                        <input
                            type="date"
                            onChange={this.onFechaFinChange}
                            defaultValue={this.state.fechaFin}
                            className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                            style={styleCalendar}
                        />
                    </div>
                    <div className="container">

                        <div className={`col-12 no-pad ${this.state.FiltrarSucursal ? '' : 'caja-fondo-blanco-m-2'}`}>
                            {this.state.FiltrarSucursal ? (
                                <>
                                    <div className="caja-fondo-blanco-m-2">
                                        <label className="sucursal-text-color" htmlFor="sucursales">Seleccione una
                                            sucursal</label>
                                        {/*<select value={this.state.idSucursal} name="sucursales" id="sucursales"*/}
                                        {/*        className="w-100 select-search-box__search"*/}
                                        {/*        onChange={this.onChangeSucursal}>*/}
                                        {/*</select>*/}
                                        <select value={this.state.idSucursal} name="sucursales" id="sucursales"
                                                className="w-100 select-search-box__search"
                                                onChange={this.onChangeSucursal}>
                                            {[...this.state.sucursales].map((s, i) => (
                                                <option key={i} value={s.IdSucursal}>{s.Nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            ) : (<label
                                    className="sucursal-text-color container">{this.state.user.sucursal.Nombre || "Sucursal del usuario en sesión"}</label>
                            )}
                        </div>

                        <div className="col-12 mt-4 no-pad">

                            <div className="d-flex justify-content-end">
                                <div className='row justify-content-end ml-0 mr-0'>
                                    <ExcelFile
                                        element={
                                            <button className="btn btn-outline-primary btn-excel-mfp">
                                                <a className="">Descargar Excel</a>
                                            </button>
                                        }
                                        filename="MovimientoAlmacen"
                                    >
                                        <ExcelSheet dataSet={this.state.dataExcel} name="MovimientosAlmacen"/>
                                    </ExcelFile>
                                    {
                                        !this.state.modalApertura && <button
                                            // title="Nuevo Movimiento"
                                            className="ml-3 btn btn-outline-primary btn-verde-mfp mb-2"
                                            type="button"
                                            onClick={this.handleClickNuevo}>
                                            Agregar movimiento
                                        </button>
                                    }

                                    <div className="ml-3">
                                        <div className="seccion-btn-verde-mfp">
                                            <button onClick={this.loadMovsAlmacenHandler}
                                                    className="btn btn-outline-primary btn-verde-mfp">Generar
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className='container'>

                        <div className='col-12 mt-3 no-pad'>
                            <div className='row'>
                                <div className="col-sm-auto mt-1">
                                    Total con comprobante: {this.state.totalConComprobante.toFixed(6)}
                                </div>
                                <div className="col-sm-auto mt-1">
                                    Total sin comprobante: {this.state.totalSinComprobante.toFixed(6)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 container">
                        <ReactTable
                            data={this.state.dataLista[1]}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}

                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Acciones",
                                            accessor: "IdMovimientoAlmacen",
                                            // headerClassName:'card-header bg-dark text-white',
                                            width: 350,
                                            filterable: false,
                                            Cell: (f) => {
                                                if (!f) {
                                                    return null
                                                }
                                                return (
                                                    <div align='rigth'>
                                                        { this.state.permisos.canUpdate &&  <BotonEditar
                                                            className="mr-2"
                                                            id={f.value}
                                                            value={f.original.RazonSocial}
                                                            disabled={f.original.Estado === 'Procesado' ? true : false}
                                                            onClick={
                                                                () => {
                                                                    this.handleClickEditar(f.original)
                                                                }
                                                            }
                                                            title="Editar"
                                                        />}

                                                        <BotonPDFpequeno
                                                            className="mr-2"
                                                            onClick={
                                                                () => {
                                                                    this.handleOpenModalPDF(f.original)
                                                                }
                                                            }
                                                            title="Descargar PDF"
                                                        />

                                                        <BotonTicket
                                                            className="mr-2"
                                                            onClick={
                                                                () => {
                                                                    this.handleDownloadTicket(f.original)
                                                                }
                                                            }
                                                            title="Ticket"
                                                        />

                                                        {this.state.permisos.canProcess && <BotonProcesarPequeno
                                                            className="mr-2"
                                                            title="procesar"
                                                            hidden={f.original.Estado === 'Procesado' || f.original.cantMovs <= 0}
                                                            onClick={
                                                                () => {
                                                                    this.ClickProcesarMA(f.original)
                                                                }
                                                            }
                                                        />}

                                                        { this.state.permisos.canDelete && <BotonDelete
                                                            id={f.value}
                                                            className="mr-2"
                                                            value={f.original.RazonSocial}
                                                            hidden={this.state.permisos.canDelete && f.original.Estado === 'Procesado'}
                                                            onClick={
                                                                () => {
                                                                    this.ClickAnularMA(f.original)
                                                                }
                                                            }
                                                            title="Anular"
                                                        />}
                                                    </div>
                                                )
                                            },
                                        },

                                        {
                                            Header: "Estado",
                                            id: "Estado",
                                            accessor: "Estado",
                                            Cell: row => <div style={{textAlign: "center"}}>{row.value}</div>,

                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                            filterMethod: (filter, row) => {
                                                if (filter.value === 'Todos') {
                                                    return true;
                                                }
                                                if (filter.value === "Sin Procesar") {
                                                    return row[filter.id] === 'Sin Procesar';
                                                }
                                                if (filter.value === "Procesado") {
                                                    return row[filter.id] === 'Procesado';
                                                }
                                            },
                                            Filter: ({filter, onChange}) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{width: "100%"}}
                                                    value={filter ? filter.value : "Todos"}

                                                >
                                                    <option value="Todos">Todos</option>
                                                    <option value="Sin Procesar">Sin Procesar</option>
                                                    <option value="Procesado">Procesado</option>
                                                </select>
                                        },

                                        {
                                            Header: "Tipo",
                                            id: "NomTipoMovimiento",
                                            accessor: d => d.NomTipoMovimiento,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["NomTipoMovimiento"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "center"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Código",
                                            id: "Numero",
                                            // accessor: "NombreCliente",
                                            width: 130,
                                            accessor: d => d.Numero,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Numero"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Fecha Movimiento",
                                            id: "FechaMovimiento",
                                            // accessor: "NombreCliente",
                                            width: 130,
                                            accessor: d => d.FechaMovimiento,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["FechaMovimiento"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "center"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: 'Número doc.',
                                            id: 'NroDocumentoSunat',
                                            accessor: d => d.NroDocumentoSunat,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["NroDocumentoSunat"]}),
                                            filterAll: true,
                                        },

                                        {
                                            Header: 'Tipo doc.',
                                            id: 'DescTipoDocSunat',
                                            width: 180,
                                            accessor: d => d.DescTipoDocSunat,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["DescTipoDocSunat"]}),
                                            filterAll: true,
                                        },

                                        {
                                            Header: "Proveedor",
                                            id: "RazonSocial",
                                            width: 250,
                                            align: 'center',
                                            accessor: d => d.RazonSocial,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["RazonSocial"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "right"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: 'Total Inafectas',
                                            id: 'totalInafectos',
                                            accessor: d => d['totalInafectos'],
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["totalInafectos"]}),
                                            filterAll: true,
                                        },

                                        {
                                            Header: 'Total Exoneradas',
                                            id: 'totalExoneradas',
                                            accessor: d => d['totalExoneradas'],
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["totalExoneradas"]}),
                                            filterAll: true,
                                        },

                                        {
                                            Header: "Total",
                                            id: "Total",
                                            // accessor: "NombreCliente",
                                            width: 100,
                                            align: 'center',
                                            accessor: d => d.Total,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Total"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "right"
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Tipo Pago",
                                            id: "tipoPago",
                                            accessor: d => d.tipoPago,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["tipoPago"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                    },
                                                };
                                            },
                                        },

                                        {
                                            Header: "Descripción",
                                            id: "Glosa",
                                            accessor: d => d.Glosa,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["Glosa"]}),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row.Estado === 'Por Procesar' ? '#FEEBEB' : null,
                                                        textAlign: "center"
                                                    },
                                                };
                                            },
                                        }
                                    ]
                                },
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPageJump={false}
                            previousText='Anterior'
                            nextText='Siguiente'
                            loadingText='Cargando...'
                            noDataText='No se encontraron registros'
                            pageText='Página'
                            ofText='de'
                            rowsText='filas'
                            style={{
                                width: "100%",
                                fontSize: "15px"
                            }}
                            pageSizeOptions={[5, 10, 20, 25, 50, 100]}

                        />
                        {
                            this.state.modalIsOpen && this.state.accion === 'Editar' ?
                                <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal} width='900px'
                                       title={`MODIFICAR: ${this.state.itemmovimientoalmacen.nroserie}`}>
                                    <MovimientoAlmacenEditar
                                        onClose={this.handleCloseModal}
                                        cambiarAccion={this.cambiarAccion}
                                        listaMovimientosAlmacenPorAlmacen={this.listaMovimientosAlmacenPorAlmacen}
                                        accion={this.state.accion}
                                        itemmovimientoalmacen={this.state.itemmovimientoalmacen}
                                        IdAlmacen={this.state.IdAlmacen}
                                        idMovAlmacen={this.state.idMovAlmacen}
                                        rucUsuario={this.state.rucUsuario}
                                        turnoCaja={this.state.turnoCaja}
                                    />
                                </Modal>
                                :
                                <Modal isOpen={this.state.modalIsOpen} onClose={this.handleCloseModal} width='900px'
                                       title="Nuevo Movimiento de Almacen">
                                    <MovimientoAlmacenEditar
                                        onClose={this.handleCloseModal}
                                        cambiarAccion={this.cambiarAccion}
                                        listaMovimientosAlmacenPorAlmacen={this.listaMovimientosAlmacenPorAlmacen}
                                        accion={this.state.accion}
                                        itemmovimientoalmacen={this.state.itemmovimientoalmacen}
                                        IdAlmacen={this.state.IdAlmacen}
                                        idMovAlmacen={this.state.idMovAlmacen}
                                        rucUsuario={this.state.rucUsuario}
                                        turnoCaja={this.state.turnoCaja}
                                    />
                                </Modal>

                        }
                        {
                            this.state.modalIsOpenPDF &&
                            <ModalPDF isOpen={this.state.modalIsOpenPDF} onClose={this.handleCloseModalPDF}
                                      width='900px'
                                      title="Visor PDF">
                                <VisorPDF fileName={`${this.state.nroMA_Seleccionado}.pdf`}>
                                    <ReporteMA extImg={this.state.extImg} itemma={this.state.itemmovimientoalmacen}
                                               cabma={this.state.cabma}
                                               detma={this.state.detma}/>
                                </VisorPDF>
                            </ModalPDF>
                        }

                        <Modal
                            isOpen={this.state.modalApertura}
                            title={"Aviso de apertura de caja"}
                            onClose={this.handleCloseModal}
                        >
                            <div className="container">
                                <div className="alert alert-warning mt-3" role="alert">
                                    <h4 className="alert-heading">Aviso!</h4>
                                    <p>
                                        Queremos informarle que hemos detectado que la tarea de cierre de caja no ha sido
                                        completada.
                                    </p>
                                </div>

                                <div className="d-flex justify-content-center my-2">
                                    <button
                                        className="btn btn-verde-mfp"
                                        onClick={() => {
                                            notificarMsg("Es necesario cerrar y abrir nuevamente. el turno de la caja y las fechas no coinciden, para garantizar una gestión financiera adecuada. Esto implica hacer un recuento de todo el efectivo, cheques y otros medios de pago, registrar los montos en un informe de cierre de caja y abrir un nuevo turno de caja con la fecha actual. Es recomendable hacerlo con regularidad para mantener una contabilidad precisa.", "warning")
                                            this.props.history.push(`/AperturarTurnosMovcaja`)
                                        }}
                                    >Ir a caja
                                    </button>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            title={`Procesar ${this.state.selectedMov ? this.state.selectedMov.NroDocumentoSunat || "" : ""}`}
                            isOpen={this.state.showModalProcesar} onClose={this.handleCloseModal}>
                            <CreditForm onSubmit={this.ProcesarMA} selectedMov={this.state.selectedMov}/>
                        </Modal>
                    </div>

                </div>
            </div>
        );
    }
}

export default MovimientoAlmacenLista;
