import React from "react";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import PreInvoiceForm from "./PreInvoiceForm";
import _ from "lodash";
import {safe} from "../../helpers/utils";
import Modal from "../Modal";
import ModalCliente from "../clientes/ClienteModal";
import moment from "moment"
import Modal2 from "../Modal2";

export default class PreInvoiceScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            conf: null,
            preventaId: null,
            endpointInvoice: "",
            accessCode: "",
            aperturarCaja: false,
            puedeVender: false,
            modalApertura: false,
            imrprimirPreventa: false,
            showModalImprimirPrev: false,
            Ruc: "",
            Empresa: "",
            Direccion: "",
            DireccionSucursal: "",
            Telefono: "",
            Sucursal: "",
            TelefonoDos: "",
            TelefonoTres: "",
            codigoValidacion: "",
            codigoValidacionInput: "",
            permisosDescuento: false,
            codigoValido: false,
            showModalValidacion: false,
            fechaDB:"",
        }
        this.initialRoute = props.history.location
        this.getAperturarCaja = this.getAperturarCaja.bind(this)
        this.getTurno = this.getTurno.bind(this)
        this.verificarImprimirPreventa = this.verificarImprimirPreventa.bind(this)
        this.showModal = this.showModal.bind(this)
        this.onKeyUpModaImprimirPreventa = this.onKeyUpModaImprimirPreventa.bind(this)
        this.getRUC = this.getRUC.bind(this)
        this.getEmpresa = this.getEmpresa.bind(this)
        this.getInfoSucursal = this.getInfoSucursal.bind(this)
        this.permisosDescuentoItem = this.permisosDescuentoItem.bind(this)
        this.openModalValidacion = this.openModalValidacion.bind(this)
        this.handleChangeCodigoDescuento = this.handleChangeCodigoDescuento.bind(this)
        this.handleCloseModalValidarDescuento = this.handleCloseModalValidarDescuento.bind(this)
        this.fetchCodigoValidacion = this.fetchCodigoValidacion.bind(this)
        this.validarCodigoUsuario = this.validarCodigoUsuario.bind(this);
        this.handleChangeCodigoAnular = this.handleChangeCodigoAnular.bind(this);
        this.traerFechaBase = this.traerFechaBase.bind(this);
    }

    async componentDidMount() {
        const location = await safe(() => this.props.history.location.pathname, "")
        const accessCode = await safe(() => this.props.location.search.replace("?code=", ""), "")
        let id = this.props.match.params.id
        await this.traerFechaBase();

        this.setState({
            preventaId: id,
            endpointInvoice: location.includes("/pre-invoice/edit/") ? "" : "/api/pre-invoice/add/v2",
            accessCode: accessCode
        })
        await this.getAperturarCaja();
        await this.verificarImprimirPreventa();
        await this.fetchCodigoValidacion();
        await this.permisosDescuentoItem();
        //await this.getRUC();
        await this.getEmpresa();
        await this.getInfoSucursal();
        this.fetchConfiguration();
    }

    async getRUC() {
        try {
            const response = await fetch(`/api/ventas/ruc`);
            const Ruc = await response.json();
            this.setState({Ruc: Ruc.respuesta[0].Ruc});
        } catch (error) {
            this.setState({error});
        }
    }
    traerFechaBase = async () => {
        try {
            const response = await fetch(`/api/ventas/traerFecha/Base`);
            const fechaBusqueda = await response.json();
            this.setState({
                fechaDB: fechaBusqueda.respuesta[0].fechaBusqueda
            })
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async getInfoSucursal() {
        try {
            const response = await fetch(`/api/guiaRemision/InfoEmpresa`);
            const data = await response.json();
            this.setState({
                Ruc: data[0].Ruc,
                Empresa: data[0].RazonSocial,
                DireccionSucursal: data[0].Direccion,
                Telefono: data[0].Telefono,
                Sucursal: data[0].Nombre,

            })
        } catch (error) {
            this.setState({error});
        }
    }

    async getEmpresa() {
        try {
            const response = await fetch(`/api/empresas/getEmpresaByUser`);
            const data = await response.json();
            this.setState({
                Direccion: data[0].Direccion,
                TelefonoDos: data[0].TelefonoDos,
                TelefonoTres: data[0].TelefonoTres
            });
        } catch (error) {
            this.setState({error});
        }
    }

    handleChangeCodigoAnular = (text) => {
        const value = text.target.value
        this.setState({codigoValidacionInput: value});
    }

    async getAperturarCaja() {
        let permiso = null
        try {
            const response = await fetch(`/api/cajas/user/validar`);
            const data = await response.json();
            permiso = data.ValidarUser == true ? true : false
        } catch (e) {
            permiso = false
        }
        this.setState({aperturarCaja: permiso})
        if (!permiso) {
            this.setState({puedeVender: true})
            return
        }
        const turnoInfo = await this.getTurno()
        if (!turnoInfo.Turno) {
            this.setState({puedeVender: false})
            notificarMsg("Se ha detectado que la caja se encuentra cerrada", "warning")
            return
        }
        const turno = turnoInfo.Turno[0][0]
        const fechaInicio = moment(turno.FechaInicio).format("YYYY-MM-DD")
        const now = this.state.fechaDB
        console.log({fechaInicio,now})
        this.setState({modalApertura: fechaInicio == now ? false : true})
        this.setState({puedeVender: fechaInicio == now ? true : false})
    }

    async getTurno() {
        try {
            const response = await fetch(`/api/cajas/verificar/Turno`);
            const dataTurno = await response.json();
            return dataTurno
        } catch (e) {
            return {Turno: null}
        }

    }

    async validarCodigoUsuario() {
        const codigoInput = this.state.codigoValidacionInput;
        const codigoUsuario = this.state.codigoValidacion;

        this.setState({codigoValidacionInput: ""})

        if (codigoInput != codigoUsuario) {
            notificarMsg('El codigo ingresado no es correcto.', 'warning')
            return
        }

        notificarMsg("Validacion completada exitosamente.");
        this.setState({codigoValido: true})
        this.setState({showModalValidacion: false})
    }

    verificarImprimirPreventa = async () => {
        try {
            const responseVal = await fetch(`/api/ventas/imprimirPreVenta/validar`);
            const data = await responseVal.json();
            this.setState({imrprimirPreventa: true})
        } catch (e) {
            this.setState({imrprimirPreventa: false})
        }
    }

    async fetchCodigoValidacion() {
        this.setState({loading: true});
        let res = await fetch('/api/usuarios/codigoConfirmacion/sucursal');
        const codigoValidacion = await res.json();
        //console.log({codigoValidacion: codigoValidacion})
        try {
            if (res.ok)
                var codigo = codigoValidacion[0].Codigo
            this.setState({codigoValidacion: codigo})
        } catch (e) {
            //
        }
        //console.log({codigoValidacion: this.state.codigoValidacion})
        this.setState({loading: false});
    }

    async permisosDescuentoItem() {
        try {
            const responseVal = await fetch('/api/usuarios/descuentoItem/validar');
            const data = await responseVal.json();
            this.setState({permisosDescuento: true})
        } catch (e) {
            this.setState({permisosDescuento: false})
        }
    }

    openModalValidacion(item) {
        this.setState({showModalValidacion: true})
    }

    handleChangeCodigoDescuento = (text) => {
        const value = text.target.value
        this.setState({codigoValidacionInput: value});
    }

    handleCloseModalValidarDescuento = (e) => {
        this.setState({
            showModalValidacion: false
        })
    }

    onKeyUpModaImprimirPreventa(e) {
        if (e.key === "Escape" || e.key === "Enter")
            this.setState({showModalImprimirPrev: false});
    }

    showModal() {
        this.setState({showModalImprimirPrev: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== this.initialRoute) {
            window.location.reload()
        }
    }

    fetchConfiguration() {
        pfetch({
            fetch: {
                url: "/api/pre-invoice/config",
                method: "GET",
                headers: defaultGetHeaders(),
            },
            before: () => this.setState({isLoading: true}),
            then: (data) => this.setState({conf: data}),
            finally: () => this.setState({isLoading: false}),
            error: () => notificarError("Ha ocurrido un error al momento de extraer la configuración")
        })
    }

    render() {
        if (this.state.isLoading || _.isNull(this.state.conf))
            return <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                </div>
            </div>

        return (
            <div className="preventa__container h-100">
                <PreInvoiceForm endpointInvoice={this.state.endpointInvoice}
                                accessCode={this.state.accessCode}
                                preventaId={this.state.preventaId}
                                puedeVender={this.state.puedeVender}
                                imprimirPreventa={this.state.imrprimirPreventa}
                                showModalImprPreventa={this.showModal}
                                showModalPermisosDescuento={this.openModalValidacion}
                                permisosDescuento={this.state.permisosDescuento}
                                codigoValido={this.state.codigoValido}
                                showSwitchStock={true}
                                Ruc={this.state.Ruc}
                                Empresa={this.state.Empresa}
                                Telefono={this.state.Telefono}
                                TelefonoDos={this.state.TelefonoDos}
                                TelefonoTres={this.state.TelefonoTres}
                                Sucursal={this.state.Sucursal}
                                Direccion={this.state.Direccion}
                                DireccionSucursal={this.state.DireccionSucursal}
                                conf={this.state.conf} onSuccessAdd={(v) => {
                    notificarMsg("Se ha realizado con éxito")
                    this.props.history.push(`/pagos/${v.IdPreventa}`)
                }}/>

                <Modal
                    isOpen={this.state.modalApertura}
                    onClose={() => this.setState({modalApertura: false})}
                    title={"Aviso de apertura de caja"}
                    handleKeyUp={this.onKeyUpModalCuotasCredito}
                >
                    <div className={this.state.modalApertura ? "container" : null}
                         style={this.state.modalApertura ? {width: "600px"} : null}>

                        <div className="alert alert-warning mt-3" role="alert">
                            <h4 className="alert-heading">Aviso!</h4>
                            <p>
                                Queremos informarle que hemos detectado que la tarea de cierre de caja no ha sido
                                completada.
                            </p>
                        </div>

                        <div className="d-flex justify-content-center my-2">
                            <button
                                className="btn btn-verde-mfp"
                                onClick={() => {
                                    notificarMsg("Es necesario cerrar y abrir nuevamente. el turno de la caja y las fechas no coinciden, para garantizar una gestión financiera adecuada. Esto implica hacer un recuento de todo el efectivo, cheques y otros medios de pago, registrar los montos en un informe de cierre de caja y abrir un nuevo turno de caja con la fecha actual. Es recomendable hacerlo con regularidad para mantener una contabilidad precisa.", "warning")
                                    this.props.history.push(`/AperturarTurnosMovcaja`)
                                }}
                            >Ir a caja
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.showModalImprimirPrev}
                    onClose={() => this.setState({showModalImprimirPrev: false})}
                    title={"Imprimir Preventa"}
                    handleKeyUp={this.onKeyUpModaImprimirPreventa}
                >
                    <div className="alert alert-warning mt-3" role="alert">
                        <h4 className="alert-heading">Aviso!</h4>
                        <p>
                            Queremos informarle que hemos detectado que la tarea de cierre de caja no ha sido
                            completada.
                        </p>
                    </div>
                </Modal>
                <ModalCliente isOpen={this.state.showModalValidacion} onClose={this.handleCloseModalValidarDescuento}
                       title="Validar Descuento de producto"
                >
                                <label className="font-weight-bold" htmlFor="inputValidacion">
                                    Codigo
                                </label>
                                <input type="password"
                                       name="inputValidacion"
                                       value={this.state.codigoValidacionInput}
                                       className="form-control mb-2"
                                       onChange={this.handleChangeCodigoAnular}/>
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-verde-mfp-letra-normal"
                            title="Validar"
                            onClick={this.validarCodigoUsuario}
                        >Validar
                        </button>
                    </div>
                </ModalCliente>
            </div>
        );
    }
}

