import jsPDF from "jspdf";
import QRCode from "qrcode";
import {Style, Section} from "./ClasesImpresion";
import {decimalAdjust, VALE} from "../../Global";
import "react-moment";
import moment from "moment";
import {loadLogoByRUC, loadImagePublic} from "../../helpers/funcionesGlobales";
import printBlob from "../../helpers/printBlob";
import { calculateVueltoToDisplay } from './utils'

async function printTicket(DetallesVenta, Venta, extImg = 'png', pedidoInfo=undefined) {
    const extraInfo = JSON.parse(window.localStorage.getItem('extraConf'))
    const showExtraInfo = extraInfo ? extraInfo.showExtraInfo : false;
    const showTwoDecimals = extraInfo ? extraInfo.showTwoDecimals : false;
    const removeNoDocText = extraInfo ? extraInfo.removeNoDocText : false
    const hasRetencion = Number(Venta.retencion) > 0
    // console.log({DetallesVenta})
    const DetalleVentaFormateado = DetallesVenta.map(producto => {
        return {
            ...producto,
            Cantidad: showTwoDecimals ? Number(decimalAdjust('floor', producto.Cantidad, -2)) : producto.Cantidad,
            NombreProductoUnidadPrecio: `${producto.NombreProducto} X S./ ${showTwoDecimals ? decimalAdjust('floor', producto.PrecioVenta, -2) : producto.PrecioVenta}`,
            PrecioVenta: showTwoDecimals ? decimalAdjust('floor', producto.PrecioVenta, -2) : producto.PrecioVenta,
            Importe: decimalAdjust('round', producto.Importe, -2),
            Descuento: producto.Descuento ? producto.Descuento : 0,
            // tipoUnidad: producto.Unidad == "UNIDAD"?,
            tipoUnidad: producto.Unidad? producto.Unidad : "",
        };
    });

    Venta.descItems = DetalleVentaFormateado.map(d => d.Descuento).reduce((a, b) => a + b, 0);


    const mmPageSize = [80, 3276];
    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);

    var doc = new jsPDF({
        orientation: "potrait",
        unit: "mm",
        format: ptPageSize
    });

    let nombreArchivo = Venta.Serie + "-" + Venta.NumeroComprobante;

    const pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();


    const logoP = loadLogoByRUC(Venta.Ruc, 'logo', extImg);
    const logo = await logoP;
    doc.addImage(logo, "PNG", 25, 1, 30, 19);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, 20, 76, 20);

    const EmpresaTittleStyle = new Style(9, "normal", 1, "center");
    const EmpresaTittleSection = new Section(
        doc,
        10,
        21,
        EmpresaTittleStyle,
        60
    );

    EmpresaTittleSection.write(Venta.Ruc, EmpresaTittleStyle);
    EmpresaTittleSection.write(Venta.Empresa.toUpperCase(), EmpresaTittleStyle);

    const EmpresaDataStyle = new Style(7, "normal", 1, "center");
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.y - 2,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    EmpresaDataSection.write(Venta.Direccion.toUpperCase());
    EmpresaDataSection.write(Venta.Sucursal.toUpperCase());
    EmpresaDataSection.write(Venta.DireccionSucursal.toUpperCase());
    if (Venta.TelefonoDos != 0 && Venta.TelefonoTres != 0) {
        EmpresaDataSection.write("TELEFONOS: " + Venta.TelefonoDos + " | " + Venta.TelefonoTres);
    } else if (Venta.TelefonoDos != 0 && Venta.TelefonoTres == 0) {
        EmpresaDataSection.write("TELEFONO: " + Venta.TelefonoDos);
    } else if (Venta.TelefonoDos == 0 && Venta.TelefonoTres != 0) {
        EmpresaDataSection.write("TELEFONO: " + Venta.TelefonoTres);
    }

    doc.line(4, EmpresaDataSection.y - 2, 76, EmpresaDataSection.y - 2);

    /** Comprobante Info**/

    const ComprobanteStyle = new Style(10, "bold", 1, "center");
    const ComprobanteSection = new Section(doc, 10, EmpresaDataSection.y - 1, ComprobanteStyle, 60);

    const ComprobanteSection2 = new Section(
        doc,
        10,
        ComprobanteSection.y + 3.5,
        ComprobanteStyle,
        ComprobanteSection.width
    );

    ComprobanteSection.write(Venta.TipoComprobante.toUpperCase());
    let guion = " - "
    if (Venta.Serie == "" && Venta.NumeroComprobante == "") {
        guion = ""
    }
    ComprobanteSection2.write(
        Venta.Serie.toUpperCase() + guion + Venta.NumeroComprobante
    );

    doc.setDrawColor(0, 0, 0);
    doc.line(4, ComprobanteSection.y + 0.5, 76, ComprobanteSection.y + 0.5);


    /** Cliente */

    const negritaStyle = new Style(8, "bold", 1);
    const newLineStyle = new Style(4, "bold");
    const clienteStyle = new Style(8, "normal", 1);
    const clienteSection = new Section(
        doc,
        4,
        ComprobanteSection2.y - 2,
        clienteStyle,
        pageWidth - 6
    );

    if (Venta.NombreCaja) {
        clienteSection.write(`${Venta.NombreCaja}`);
    }
    if (Venta.NombreEmpleado) {
        clienteSection.write(`${Venta.NombreEmpleado} ${Venta.ApellidoEmpleado}`);
    }
    clienteSection.write(`FECHA DE EMISIÓN: ${moment(Venta.FechaEmision).format("DD-MM-YYYY HH:mm")}`);

    clienteSection.write(" ", newLineStyle);

    clienteSection.write(
        `RAZÓN SOCIAL: ${Venta.RazonSocial}`,
        negritaStyle
    );

    clienteSection.write(" ", newLineStyle);

    clienteSection.write(`NRO. DOC.: ${Venta.NroTipoDocumento}`, negritaStyle);

    if (Venta.GuiaRemision) {
        clienteSection.write(`GUIA DE REMISIÓN REMITENTE: ${Venta.GuiaRemision.SerieGR}-${Venta.GuiaRemision.NumeroComprobanteGR}`);
    }

    if (!!Venta.OrdenCompra && Venta.OrdenCompra !== '') {
        clienteSection.write(`ORDEN DE COMPRA: ${Venta.OrdenCompra}`);
    }

    if (Venta.Celular) {
        clienteSection.write(" ", newLineStyle);
        clienteSection.write(`N° CEL: ${Venta.Celular}`);
    }
    if (Venta.ClienteDireccion) {
        clienteSection.write(" ", newLineStyle);
        clienteSection.write(`DIRECCIÓN: ${Venta.ClienteDireccion}`);
    }

    doc.setDrawColor(0, 0, 0);
    doc.line(4, clienteSection.y - 1, 76, clienteSection.y - 1);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, clienteSection.y + 4.5, 76, clienteSection.y + 4.5);

    /** */
    const indexElementosResaltar = [];
    doc.autoTable({
        body: DetalleVentaFormateado,
        // willDrawCell: function (data){
        //     console.log({data})
        // },
        bodyStyles: {
            fontSize: 9,
            fontStyle: "normal",
            cellPadding: 1
        },
        headStyles: {fontSize: 6.9},
        startY: clienteSection.y - 2,
        showHead: "firstPage",
        margin: {
            right: 3,
            left: 3,
            // bottom: 1
        },
        rowPageBreak: "avoid",
        columnStyles: {
            NombreProductoUnidadPrecio: {columnWidth: 30, fontSize: 8},
            Cantidad: {columnWidth: 5,fontSize: 10},
            Unidad: {columnWidth: 5,fontSize: 5},
            Importe: {columnWidth: 5, halign: "right",fontSize: 8},
            Descuento: {columnWidth: 5, halign: "right",fontSize: 8}
        },
        styles: {
            overflow: "linebreak",
            tableWidth: "auto",
            columnWidth: "auto",
            halign: "left"
        },
        columns: [
            {header: "PROD", dataKey: "NombreProductoUnidadPrecio"},
            {header: "CANT", dataKey: "Cantidad"},
            {header: "UNIDAD", dataKey: "Unidad", },
            {header: "DESC", dataKey: "Descuento"},
            {header: "TOTAL", dataKey: "Importe"},
        ],
        theme: "plain"
    });
    doc.setFontSize(10);
    doc.setFontType("italic");

    let finalTable = doc.autoTable.previous.finalY - 1;
    doc.setDrawColor(0, 0, 0);
    doc.line(4, finalTable, 76, finalTable);

    const totalesStyle = new Style(10, "bold", 1.15, "right");
    const totalesTittleSection = new Section(doc, 21, 0, totalesStyle, 27);
    totalesTittleSection.paddingY = 0.5

    let totalesTitle = ["TOTAL:  " + `${Venta.Simbolo}`];

    if (Venta.Redondeo > 0 && showExtraInfo) totalesTitle.push("REDONDEO:  " + `${Venta.Simbolo}`)
    if (Venta.DescuentoTotal > 0) totalesTitle.push("DESCUENTO:  " + `${Venta.Simbolo}`)
    // if (Venta.Vuelto > 0) totalesTitle.push("VUELTO:  " + `${Venta.Simbolo}`)
    if (showExtraInfo) totalesTitle.push("T.PAGAR:  " + `${Venta.Simbolo}`)
    if (hasRetencion) totalesTitle.push("IMP. NETO:  " + `${Venta.Simbolo}`)


    // if (Venta.IdTipoDocumentoSunat !== VALE) {
    let ImpuestosTitle = [];
    if (Venta.descItems > 0) ImpuestosTitle.push("T. DESC.:  " + `${Venta.Simbolo}`)
    if (Venta.Exoneradas > 0) ImpuestosTitle.push("EXO:  " + `${Venta.Simbolo}`)
    if (Venta.Inafectas > 0) ImpuestosTitle.push("INA:  " + `${Venta.Simbolo}`)
    if (Venta.Gratuitas > 0) ImpuestosTitle.push("GRATUITO:  " + `${Venta.Simbolo}`)
    if (Venta.Gravadas > 0) ImpuestosTitle.push("GRAVADO:  " + `${Venta.Simbolo}`)
    if (Venta.IGV > 0) ImpuestosTitle.push("IGV:  " + `${Venta.Simbolo}`)
    if (Venta.ICBPER > 0) ImpuestosTitle.push("ICBPER:  " + `${Venta.Simbolo}`)
    if (Venta.ISC > 0) ImpuestosTitle.push("ISC:  " + `${Venta.Simbolo}`)
    if (Venta.IVAP > 0) ImpuestosTitle.push("IVAP:  " + `${Venta.Simbolo}`)

    totalesTitle = [...ImpuestosTitle, ...totalesTitle];
    // }

    totalesTittleSection.y = finalTable + 1;

    totalesTittleSection.write(totalesTitle);

    const totalesMontoSection = new Section(doc, 50, 50, totalesStyle, 23);
    let gravadas = Venta.Gravadas.toFixed(2);
    let exoneradas = String(decimalAdjust('floor', Venta.Exoneradas, -2));
    let inafectas = String(decimalAdjust('floor', Venta.Inafectas, -2));
    let gratuitas = String(decimalAdjust('floor', Venta.Gratuitas, -2));
    let igv = Venta.IGV.toFixed(2);
    let icbper = String(decimalAdjust('floor', Venta.ICBPER, -2));
    let isc = String(decimalAdjust('floor', Venta.ISC, -2));
    let ivap = String(decimalAdjust('floor', Venta.IVAP, -2));
    let total = String(Number(decimalAdjust('round', Venta.Total, -2)).toFixed(2));
    let Redondeo = `${decimalAdjust('floor', Venta.Redondeo, -2)}`;
    let TotalRedondeo = String(decimalAdjust('floor', Venta.TotalRedondeo, -2));
    let descItems = String(decimalAdjust('floor', Venta.descItems, -2))
    const _impNeto = Venta.Total - (Venta.Total * (Venta.retencion / 100))
    const importeNeto = String(decimalAdjust('floor', _impNeto, -2))

    let vuelto = calculateVueltoToDisplay(Venta);
    
    let descuento = String(Venta.DescuentoTotal);

    let totales = [
        `${total}`
    ];

    if (Venta.Redondeo > 0 && showExtraInfo) totales.push(Redondeo)
    if (Venta.DescuentoTotal > 0) totales.push(descuento)
    // if (Venta.Vuelto > 0) totales.push(vuelto)
    if (showExtraInfo) totales.push(TotalRedondeo)
    if (hasRetencion > 0) totales.push(importeNeto)

    // if (Venta.IdTipoDocumentoSunat !== VALE) {

    let Impuestos = [];

    if (Venta.descItems > 0) Impuestos.push(descItems)
    if (Venta.Exoneradas > 0) Impuestos.push(exoneradas)
    if (Venta.Inafectas > 0) Impuestos.push(inafectas)
    if (Venta.Gratuitas > 0) Impuestos.push(gratuitas)
    if (Venta.Gravadas > 0) Impuestos.push(gravadas)
    if (Venta.IGV > 0) Impuestos.push(igv)
    if (Venta.ICBPER > 0) Impuestos.push(icbper)
    if (Venta.ISC > 0) Impuestos.push(isc)
    if (Venta.IVAP > 0) Impuestos.push(ivap)


    totales = [...Impuestos, ...totales];
    // }

    totalesMontoSection.y = finalTable + 1;

    totalesMontoSection.write(totales);


    const LetrasStyle = new Style(7, "normal", 1);
    const LetrasSection = new Section(
        doc,
        4,
        totalesMontoSection.y + 1,
        LetrasStyle,
        72
    );

    LetrasSection.write(`SON: ${Venta.Letras}`);
    LetrasSection.write(`MODALIDAD DE PAGO: ${Venta.IdModalidadPago}`);
    // LetrasSection.write(` `);
    if (!pedidoInfo && Venta.IdModalidadPago === "CRÉDITO") {
        LetrasSection.write(`CANTIDAD DE DÍAS: ${Venta.CantidadDiasCredito}`)
        LetrasSection.write(`FECHA DE PAGO: ${Venta.FechaPago}`)
    } else if (!!pedidoInfo) {
        LetrasSection.write(`MONTO PAGADO: S/ ${pedidoInfo.montoPagado || 0}`)
        LetrasSection.write(`RESTANTE: S/ ${pedidoInfo.restante}`)
    }
    doc.setDrawColor(0, 0, 0);
    doc.line(4, totalesMontoSection.y - 1, 76, totalesMontoSection.y - 1);

    const AliasStyle = new Style(8, "normal", 1);
    const AliasSection = new Section(
        doc,
        5,
        LetrasSection.y + 1,
        new Style(9, "bold", 1),
        75
    );
    doc.setDrawColor(0, 0, 0);
    doc.line(4, LetrasSection.y, 76, LetrasSection.y);

    Venta.Observacion = (Venta.Observacion ? Venta.Observacion : "") + (hasRetencion ? `-RETENCIÓN EN CUOTAS DEL ${Venta.retencion} %` : "")
    const tmpObs = Venta.Observacion;
    // AliasSection.write(`ALIAS: ${Venta.Alias.toUpperCase()}`);
    (Venta.Alias || Venta.Observacion) && AliasSection.write(`${Venta.Alias ? 'ALIAS/PLACA: ' + Venta.Alias.toUpperCase() : ''} \n${Venta.Observacion && Venta.Observacion.length ? 'OBS: ' + tmpObs.toUpperCase() : ''}`);

    if (!!pedidoInfo) {
        AliasSection.write(`FECHA DE ENTREGA: ${pedidoInfo.fechaEntrega}`)
    }

    if (Venta.IdTipoDocumentoSunat !== VALE) {
        let QR = await QRCode.toDataURL(
            Venta.Ruc +
            "|" +
            Venta.CodigoTipoComprobante +
            "|" +
            Venta.Serie +
            "|" +
            Venta.NumeroComprobante +
            "|" +
            Venta.IGV +
            "|" +
            Venta.Total +
            "|" +
            moment(Venta.FechaEmision).format("DD-MM-YYYY") +
            "|" +
            Venta.CodigoDocumentoCliente +
            "|" +
            Venta.NroTipoDocumento +
            "|"
        );
        const imgQR = await loadImagePublic(QR);

        doc.addImage(imgQR, "PNG", 4, AliasSection.y + 1);
        const logom = loadImagePublic("/mifacturaperu.png");
        const logoMFP = await logom;

        doc.addImage(logoMFP, "PNG", 47, AliasSection.y + 7, 30, 21);


        const footerDescY = AliasSection.y + 44;

        const FirmaStyle = new Style(6, "normal", 1, "center");
        const FirmaSection = new Section(
            doc,
            5,
            footerDescY,
            FirmaStyle,
            pageWidth - 10
        );

        const FirmaSection1 = new Section(
            doc,
            5,
            footerDescY + 5,
            FirmaStyle,
            pageWidth - 10
        );

        const FirmaSection2 = new Section(
            doc,
            5,
            footerDescY + 8,
            FirmaStyle,
            pageWidth - 10
        );

        const FirmaSection3 = new Section(
            doc,
            5,
            footerDescY + 11,
            FirmaStyle,
            pageWidth - 10
        );


        const firmaMtoSunat = new Style(6, "bold", 2, "center");
        const FirmaSection4 = new Section(
            doc,
            5,
            footerDescY + 15,
            firmaMtoSunat,
            pageWidth - 10
        );


        FirmaSection.write(
            `REPRESENTACIÓN IMPRESA DE LA ${Venta.TipoComprobante.toUpperCase()}.`
        );
        
        FirmaSection1.write(`PARA CONSULTAR EL DOCUMENTO VISITA`)

        FirmaSection3.write(`WWW.MIFACTURAPERU.COM`);
        
        FirmaSection2.write(`AUTORIZADO MEDIANTE R.S. Nº 155-2017-SUNAT`);
        
        if (Venta.IdModalidadPago !== "ES UNA PREVENTA") {
            FirmaSection4.write(
                `ENCUENTRAME EN SUNAT CON EL MONTO: ${String(Number(decimalAdjust('round', Venta.Total, -2)).toFixed(2))}`
            )
        }

    }

    let finalTicket = AliasSection.y + 1.5;

    const mensaje = await(async () => {
        try {
            const fetchMensaje = await fetch('/api/ventas/imprimir/mensaje');

            if (!fetchMensaje.ok) {
                throw new Error(`HTTP error! status: ${fetchMensaje.status}`);
            }

            const { respuesta } = await fetchMensaje.json();

            if (!respuesta || !('Mensaje' in respuesta)) {
                throw new Error('No se pudo obtener el mensaje');
            }

            return respuesta.Mensaje.toUpperCase();
        } catch (e) {
            console.error(e)
        }

        return null;
    })();

    if (Venta.IdTipoDocumentoSunat === VALE) {
        const MensajeStyle = new Style(6, "normal", 1, "center");

        const MensajeSection = new Section(
            doc,
            5,
            finalTicket,
            MensajeStyle,
            pageWidth - 10
        );

        MensajeSection.write(mensaje);

        finalTicket = MensajeSection.y + 6;
    } else {
        const mensajePos = AliasSection.y + 62.5;

        const MensajeStyle = new Style(6, "normal", 1, "center");

        const MensajeSection = new Section(
            doc,
            5,
            mensajePos,
            MensajeStyle,
            pageWidth - 10
        );

        MensajeSection.write(mensaje);
    }

    if (Venta.IdTipoDocumentoSunat === VALE && !removeNoDocText) {
        const NoValidoStyle = new Style(9, "bold", 1);

        const NoValidoSection = new Section(
            doc,
            5,
            finalTicket,
            NoValidoStyle,
            pageWidth - 10
        );

        NoValidoSection.write(`NO ES UN COMPROBANTE DE PAGO`);

        
    }

    doc.autoPrint();
    printBlob(doc.output("bloburl"));
}

export async function printTicketPreventa(DetallesVenta, Venta, extImg = 'png') {
    console.log({DetallesVenta,Venta,extImg})
    const extraInfo = JSON.parse(window.localStorage.getItem('extraConf'))
    const showExtraInfo = extraInfo ? extraInfo.showExtraInfo : false;
    const showTwoDecimals = extraInfo ? extraInfo.showTwoDecimals : false;
    const removeNoDocText = extraInfo ? extraInfo.removeNoDocText : false
    const hasRetencion = Number(Venta.retencion) > 0

    const DetalleVentaFormateado = DetallesVenta.map(producto => {
        return {
            ...producto,
            Cantidad: showTwoDecimals ? Number(decimalAdjust('floor', producto.Cantidad, -2)) : producto.Cantidad,
            NombreProductoUnidadPrecio: `${producto.NombreProducto} X S./ ${showTwoDecimals ? decimalAdjust('floor', producto.PrecioVenta, -2) : producto.PrecioVenta}`,
            PrecioVenta: showTwoDecimals ? decimalAdjust('floor', producto.PrecioVenta, -2) : producto.PrecioVenta,
            Importe: decimalAdjust('round', producto.Importe, -2),
            Descuento: producto.Descuento ? producto.Descuento : 0,
        };
    });

    Venta.descItems = DetalleVentaFormateado.map(d => d.Descuento).reduce((a, b) => a + b, 0);


    const mmPageSize = [80, 500];
    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);

    var doc = new jsPDF({
        orientation: "potrait",
        unit: "mm",
        format: ptPageSize
    });

    let nombreArchivo = Venta.Serie + "-" + Venta.NumeroComprobante;

    const pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();


    //const logoP = loadLogoByRUC(Venta.Ruc, 'logo', extImg);
    //const logo = await logoP;
    //doc.addImage(logo, "PNG", 25, 1, 30, 19);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, 20, 76, 20);

    const EmpresaTittleStyle = new Style(9, "normal", 1, "center");
    const EmpresaTittleSection = new Section(
        doc,
        10,
        20,
        EmpresaTittleStyle,
        60
    );

    // EmpresaTittleSection.write(Venta.Ruc, EmpresaTittleStyle);
    //EmpresaTittleSection.write(Venta.Empresa.toUpperCase(), EmpresaTittleStyle);

    const EmpresaDataStyle = new Style(7, "normal", 1, "center");
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.y - 3,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    /*
    EmpresaDataSection.write(Venta.Direccion.toUpperCase());
    EmpresaDataSection.write(Venta.Sucursal.toUpperCase());
    EmpresaDataSection.write(Venta.DireccionSucursal.toUpperCase());
    if (Venta.TelefonoDos != 0 && Venta.TelefonoTres != 0) {
        EmpresaDataSection.write("TELEFONOS: " + Venta.TelefonoDos + " | " + Venta.TelefonoTres);
    } else if (Venta.TelefonoDos != 0 && Venta.TelefonoTres == 0) {
        EmpresaDataSection.write("TELEFONO: " + Venta.TelefonoDos);
    } else if (Venta.TelefonoDos == 0 && Venta.TelefonoTres != 0) {
        EmpresaDataSection.write("TELEFONO: " + Venta.TelefonoTres);
    }
    */

    doc.line(4, EmpresaDataSection.y - 3, 76, EmpresaDataSection.y - 3);

    /** Comprobante Info**/

    const ComprobanteStyle = new Style(10, "bold", 1, "center");
    const ComprobanteSection = new Section(doc, 10, EmpresaDataSection.y - 1, ComprobanteStyle, 60);

    const ComprobanteSection2 = new Section(
        doc,
        10,
        ComprobanteSection.y + 3.5,
        ComprobanteStyle,
        ComprobanteSection.width
    );

    ComprobanteSection.write(Venta.TipoComprobante.toUpperCase());
    /*
    let guion = " - "
    if (Venta.Serie == "" && Venta.NumeroComprobante == "") {
        guion = ""
    }
    ComprobanteSection2.write(
        Venta.Serie.toUpperCase() + guion + Venta.NumeroComprobante
    );
     */
    doc.setDrawColor(0, 0, 0);
    // doc.line(4, ComprobanteSection.y + 0.5, 76, ComprobanteSection.y + 2);


    /** Cliente */

    const negritaStyle = new Style(8, "bold", 1);
    const newLineStyle = new Style(4, "bold");
    const clienteStyle = new Style(8, "normal", 1);
    const clienteSection = new Section(
        doc,
        4,
        ComprobanteSection2.y + 2,
        clienteStyle,
        pageWidth - 6
    );
/*
    if (Venta.NombreCaja) {
        clienteSection.write(`${Venta.NombreCaja}`);
    }
    if (Venta.NombreEmpleado) {
        clienteSection.write(`${Venta.NombreEmpleado} ${Venta.ApellidoEmpleado}`);
    }

 */
    clienteSection.write(`FECHA DE EMISIÓN: ${moment(Venta.FechaEmision).format("DD-MM-YYYY HH:mm")}`);

    clienteSection.write(" ", newLineStyle);

    clienteSection.write(
        `RAZÓN SOCIAL: ${Venta.RazonSocial}`,
        negritaStyle
    );

    clienteSection.write(" ", newLineStyle);

    clienteSection.write(`NRO. DOC.: ${Venta.NroTipoDocumento}`, negritaStyle);
    /*
    if (Venta.ClienteDireccion) {
        clienteSection.write(" ", newLineStyle);
        clienteSection.write(`DIRECCIÓN: ${Venta.ClienteDireccion}`);
    }
     */
    doc.setDrawColor(0, 0, 0);
    doc.line(4, clienteSection.y - 1, 76, clienteSection.y - 1);

    doc.setDrawColor(0, 0, 0);
    doc.line(4, clienteSection.y + 4.5, 76, clienteSection.y + 4.5);



    doc.autoTable({
        body: DetalleVentaFormateado,
        bodyStyles: {
            fontSize: 9,
            fontStyle: "normal",
            cellPadding: 1
        },
        headStyles: {fontSize: 6.9},
        startY: clienteSection.y - 2,
        showHead: "firstPage",
        margin: {
            right: 3,
            left: 3,
            // bottom: 1
        },

        rowPageBreak: "avoid",
        columnStyles: {
            NombreProductoUnidadPrecio: {columnWidth: 41, fontSize: 7},
            Cantidad: {columnWidth: 5},
            Importe: {columnWidth: 5, halign: "right"},
            Descuento: {columnWidth: 5, halign: "right"}
        },
        styles: {
            overflow: "linebreak",
            tableWidth: "auto",
            columnWidth: "auto",
            halign: "left"
        },
        columns: [
            {header: "PROD", dataKey: "NombreProductoUnidadPrecio"},
            {header: "CANT", dataKey: "Cantidad"},
            {header: "DESC", dataKey: "Descuento"},
            {header: "TOTAL", dataKey: "Importe"}
        ],
        theme: "plain"
    });
    doc.setFontSize(10);
    doc.setFontType("italic");

    let finalTable = doc.autoTable.previous.finalY - 1;
    doc.setDrawColor(0, 0, 0);
    doc.line(4, finalTable, 76, finalTable);

    const totalesStyle = new Style(10, "bold", 1.15, "right");
    const totalesTittleSection = new Section(doc, 23, 0, totalesStyle, 27);
    totalesTittleSection.paddingY = 0.5

    let totalesTitle = ["TOTAL:  " + `${Venta.Simbolo}`];

    if (Venta.Redondeo > 0 && showExtraInfo) totalesTitle.push("REDONDEO:  " + `${Venta.Simbolo}`)
    if (Venta.DescuentoTotal > 0) totalesTitle.push("DESCUENTO:  " + `${Venta.Simbolo}`)
    // if (Venta.Vuelto > 0) totalesTitle.push("VUELTO:  " + `${Venta.Simbolo}`)
    if (showExtraInfo) totalesTitle.push("T.PAGAR:  " + `${Venta.Simbolo}`)
    if (hasRetencion) totalesTitle.push("IMP. NETO: " + `${Venta.Simbolo}`)


    // if (Venta.IdTipoDocumentoSunat !== VALE) {
    let ImpuestosTitle = [];
    if (Venta.descItems > 0) ImpuestosTitle.push("T. DESC.:  " + `${Venta.Simbolo}`)
    if (Venta.Exoneradas > 0) ImpuestosTitle.push("EXO:  " + `${Venta.Simbolo}`)
    if (Venta.Inafectas > 0) ImpuestosTitle.push("INA:  " + `${Venta.Simbolo}`)
    if (Venta.Gratuitas > 0) ImpuestosTitle.push("GRATUITO:  " + `${Venta.Simbolo}`)
    if (Venta.Gravadas > 0) ImpuestosTitle.push("GRAVADO:  " + `${Venta.Simbolo}`)
    if (Venta.IGV > 0) ImpuestosTitle.push("IGV:  " + `${Venta.Simbolo}`)
    if (Venta.ICBPER > 0) ImpuestosTitle.push("ICBPER:  " + `${Venta.Simbolo}`)
    if (Venta.ISC > 0) ImpuestosTitle.push("ISC:  " + `${Venta.Simbolo}`)
    if (Venta.IVAP > 0) ImpuestosTitle.push("IVAP:  " + `${Venta.Simbolo}`)

    totalesTitle = [...ImpuestosTitle, ...totalesTitle];
    // }

    totalesTittleSection.y = finalTable + 1;

    totalesTittleSection.write(totalesTitle);

    const totalesMontoSection = new Section(doc, 50, 50, totalesStyle, 25);
    let gravadas = Venta.Gravadas.toFixed(2);
    let exoneradas = String(decimalAdjust('floor', Venta.Exoneradas, -2));
    let inafectas = String(decimalAdjust('floor', Venta.Inafectas, -2));
    let gratuitas = String(decimalAdjust('floor', Venta.Gratuitas, -2));
    let igv = Venta.IGV.toFixed(2);
    let icbper = String(decimalAdjust('floor', Venta.ICBPER, -2));
    let isc = String(decimalAdjust('floor', Venta.ISC, -2));
    let ivap = String(decimalAdjust('floor', Venta.IVAP, -2));
    let total = String(Number(decimalAdjust('round', Venta.Total, -2)).toFixed(2));
    let Redondeo = String(decimalAdjust('floor', Venta.Redondeo, -2));
    let TotalRedondeo = String(decimalAdjust('floor', Venta.TotalRedondeo, -2));
    let descItems = String(decimalAdjust('floor', Venta.descItems, -2))
    const _impNeto = Venta.Total - (Venta.Total * (Venta.retencion / 100))
    const importeNeto = String(decimalAdjust('floor', _impNeto, -2))

    let vuelto = calculateVueltoToDisplay(Venta);
    
    let descuento = String(Venta.DescuentoTotal);

    let totales = [
        `${total}`
    ];

    if (Venta.Redondeo > 0 && showExtraInfo) totales.push(Redondeo)
    if (Venta.DescuentoTotal > 0) totales.push(descuento)
    // if (Venta.Vuelto > 0) totales.push(vuelto)
    if (showExtraInfo) totales.push(TotalRedondeo)
    if (hasRetencion > 0) totales.push(importeNeto)

    // if (Venta.IdTipoDocumentoSunat !== VALE) {

    let Impuestos = [];

    if (Venta.descItems > 0) Impuestos.push(descItems)
    if (Venta.Exoneradas > 0) Impuestos.push(exoneradas)
    if (Venta.Inafectas > 0) Impuestos.push(inafectas)
    if (Venta.Gravadas > 0) Impuestos.push(gravadas)
    if (Venta.Gratuitas > 0) Impuestos.push(gratuitas)
    if (Venta.IGV > 0) Impuestos.push(igv)
    if (Venta.ICBPER > 0) Impuestos.push(icbper)
    if (Venta.ISC > 0) Impuestos.push(isc)
    if (Venta.IVAP > 0) Impuestos.push(ivap)


    totales = [...Impuestos, ...totales];
    // }

    totalesMontoSection.y = finalTable + 1;

    totalesMontoSection.write(totales);


    const LetrasStyle = new Style(7, "normal", 1);
    const LetrasSection = new Section(
        doc,
        4,
        totalesMontoSection.y + 1,
        LetrasStyle,
        72
    );

    LetrasSection.write(`SON: ${Venta.Letras}`);
    LetrasSection.write(`MODALIDAD DE PAGO: ${Venta.IdModalidadPago}`);
    // LetrasSection.write(` `);
    if (Venta.IdModalidadPago === "CRÉDITO") {
        LetrasSection.write(`CANTIDAD DE DÍAS: ${Venta.CantidadDiasCredito}`)
        LetrasSection.write(`FECHA DE PAGO: ${Venta.FechaPago}`)
    }
    doc.setDrawColor(0, 0, 0);
    doc.line(4, totalesMontoSection.y - 1, 76, totalesMontoSection.y - 1);

    const AliasStyle = new Style(8, "normal", 1);
    const AliasSection = new Section(
        doc,
        5,
        LetrasSection.y + 1,
        new Style(9, "bold", 1),
        75
    );
    doc.setDrawColor(0, 0, 0);
    doc.line(4, LetrasSection.y, 76, LetrasSection.y);

    Venta.Observacion = (Venta.Observacion ? Venta.Observacion : "") + (hasRetencion ? `-RETENCIÓN EN CUOTAS DEL ${Venta.retencion} %` : "")
    const tmpObs = Venta.Observacion;
    // AliasSection.write(`ALIAS: ${Venta.Alias.toUpperCase()}`);
    (Venta.Alias || Venta.Observacion) && AliasSection.write(`${Venta.Alias ? 'ALIAS/PLACA: ' + Venta.Alias.toUpperCase() : ''} \n${Venta.Observacion && Venta.Observacion.length ? 'OBS: ' + tmpObs.toUpperCase() : ''}`);

    if (Venta.IdTipoDocumentoSunat !== VALE) {
        let QR = await QRCode.toDataURL(
            Venta.Ruc +
            "|" +
            Venta.CodigoTipoComprobante +
            "|" +
            Venta.Serie +
            "|" +
            Venta.NumeroComprobante +
            "|" +
            Venta.IGV +
            "|" +
            Venta.Total +
            "|" +
            moment(Venta.FechaEmision).format("DD-MM-YYYY") +
            "|" +
            Venta.CodigoDocumentoCliente +
            "|" +
            Venta.NroTipoDocumento +
            "|"
        );

        /*
        const imgQR = await loadImagePublic(QR);

        doc.addImage(imgQR, "PNG", 4, AliasSection.y + 1);
        const logom = loadImagePublic("/mifacturaperu.png");
        const logoMFP = await logom;

        doc.addImage(logoMFP, "PNG", 47, AliasSection.y + 7, 30, 21);


        const footerDescY = AliasSection.y + 46;

        const FirmaStyle = new Style(6, "normal", 1);
        const FirmaSection = new Section(
            doc,
            9.5,
            footerDescY,
            FirmaStyle,
            70
        );

        const FirmaSection1 = new Section(
            doc,
            18,
            footerDescY + 3,
            FirmaStyle,
            72
        );

        const FirmaSection2 = new Section(
            doc,
            15,
            footerDescY + 6,
            FirmaStyle,
            60
        );

        const FirmaSection3 = new Section(
            doc,
            24,
            footerDescY + 9,
            FirmaStyle,
            60
        );


        const firmaMtoSunat = new Style(6, "bold", 2);
        const FirmaSection4 = new Section(
            doc,
            9.5,
            footerDescY + 12,
            firmaMtoSunat,
            60
        );


        FirmaSection.write(
            `REPRESENTACIÓN IMPRESA DE LA ${Venta.TipoComprobante.toUpperCase()}.`
        );

        FirmaSection1.write(`PARA CONSULTAR EL DOCUMENTO VISITA`);

        FirmaSection2.write(`AUTORIZADO MEDIANTE R.S. Nº 155-2017-SUNAT`);

        FirmaSection3.write(`WWW.MIFACTURAPERU.COM`);

        if (Venta.IdModalidadPago !== "ES UNA PREVENTA") {
            FirmaSection4.write(
                `ENCUENTRAME EN SUNAT CON EL MONTO: ${String(Number(decimalAdjust('round', Venta.Total, -2)).toFixed(2))}`
            )
        }

    }

    const finalTicket = AliasSection.y + 1.5;


    if (Venta.IdTipoDocumentoSunat === VALE && !removeNoDocText) {
        const NoValidoStyle = new Style(9, "bold", 1);

        const NoValidoSection = new Section(
            doc,
            12,
            finalTicket,
            NoValidoStyle,
            60
        );

        NoValidoSection.write(`NO ES UN COMPROBANTE DE PAGO`);
    }
    */
        doc.autoPrint();
        printBlob(doc.output("bloburl"));
    }
}

export default printTicket


