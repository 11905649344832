import React, { useState, useEffect } from 'react';
import "../Plantillas/Estilos.css";
import Modal from "../clientes/ClienteModal";
import ReactTable from "react-table";
import matchSorter from 'match-sorter';
import { REGISTRAR_ACTION, EDITAR_ACTION, UNDEFINED_ACTION } from './constants';
import { BotonDelete, BotonEditar, BotonInformacion } from '../Plantillas/Botones';
import ComboForm from './Form';
import { notificarMsg } from '../Almacenes/AlmacenNotify';


export default function() {
  const [action, setAction] = useState(UNDEFINED_ACTION);
  const [modalTitle, setModalTitle] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [combos, setCombos] = useState([]);

  useEffect(() => {
    refreshList()
  }, [])

  const refreshList = async () => {
    try {
      const req = await fetch('/api/combos', {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Content': 'application/json',
        }
      });

      if (!req.ok) {
        throw new Error();
      }

      const items = await req.json();

      setCombos(items);
    } catch (e) {
      console.error(e)
    }
  }

  const onCreate = () => {
    setCurrentId(undefined);
    setAction(REGISTRAR_ACTION);
    setModalTitle("Registrar combo");
  }

  const onEdit = (comboId) => {
    setCurrentId(comboId);
    setModalTitle(`Editar combo`);
    setAction(EDITAR_ACTION);
  }

  const onDelete = async (comboId) => {
    try {
      const req = await fetch(`/api/combos/${comboId}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'Accept-Content': 'application/json',
        }
      });

      if (!req.ok) {
        throw new Error();
      }
      
      notificarMsg("Se eliminó correctamente.")

      refreshList();
    } catch (e) {
      console.error(e)
    }
  }

  const toggleState = async (combo) => {
    try {
      const req = await fetch(`/api/combos/${combo.IdCombo}`, {
        method: "PATCH",
        body: JSON.stringify({
          Estado: combo.Estado == "Activo" ? "Inactivo" : "Activo",
        }),
        headers: {
          'Content-Type': 'application/json',
          'Accept-Content': 'application/json',
        }
      });
  
      if (!req.ok) {
        throw new Error(await req.text())
      }

      notificarMsg("Se actualizó correctamente el combo.")
    } catch (e) {
      console.error(e);
    }

    await refreshList();
  }

  const onCloseForm = () => {
    setCurrentId(undefined);
    setAction(UNDEFINED_ACTION);
    setModalTitle("");
    refreshList();
  }

  return (<>
    <div className="ventas-fpay-section">
      <div className="mt-3 container">
        <div className="d-flex justify-content-end">
          <button 
            className="ml-3 btn btn-outline-primary btn-verde-mfp mb-2"
            type="button"
            onClick={() => onCreate()}
          >
            Registrar combo
          </button>
        </div>
      </div>

      <div className="mt-3 container">
        <ReactTable
          data={combos}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              columns: [
                {
                  Header: "Acciones",
                  accessor: "IdCombo",
                  width: "250",
                  filterable: false,
                  Cell: (f) => {
                    if (!f) {
                      return null;
                    }
                    return (
                      <div align="rigth"> 
                        <BotonEditar
                          className="mr-2"
                          id={`${f.value}-edit`}
                          onClick={() => onEdit(f.original.IdCombo)}
                          title="Editar"
                        />
                        <BotonDelete
                          id={`${f.value}-delete`}
                          className="mr-2"
                          onClick={() => onDelete(f.original.IdCombo)}
                          title="Anular"
                        />
                        <button className="btn-verde-mfp" onClick={() => toggleState(f.original)} style={{fontSize: "1rem"}}>
                          { f.original.Estado === "Activo" ? "Desactivar" : "Activar" }
                        </button>
                      </div>
                    );
                  },
                },
                {
                  Header: "Estado",
                  id: "Estado",
                  width: "90",
                  align: "center",
                  accessor: (d) => d.Estado,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["Estado"] }),
                  filterAll: true,
                  getProps: (state, rowInfo, column) => {
                    return {
                      style: {
                        background:
                          rowInfo && rowInfo.row.Estado === "Activo"
                            ? "#33A744"
                            : null,
                        color:
                            rowInfo && rowInfo.row.Estado === "Activo"
                              ? "white"
                              : "#DE6449",
                        textAlign: "center",
                      },
                    };
                  },
                },
                {
                  Header: "Nombre",
                  id: "Nombre",
                  width: "300",
                  align: "center",
                  accessor: (d) => d.Nombre,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["Nombre"] }),
                  filterAll: true,
                },
                {
                  Header: "Codigo barras",
                  id: "CodigoBarras",
                  width: "300",
                  align: "center",
                  accessor: (d) => d.CodigoBarras,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["CodigoBarras"] }),
                  filterAll: true,
                },
              ],
            },
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
          showPageJump={false}
          previousText="Anterior"
          nextText="Siguiente"
          loadingText="Cargando..."
          noDataText="No se encontraron registros"
          pageText="Página"
          ofText="de"
          rowsText="filas"
          style={{
            width: "100%",
            fontSize: "15px",
          }}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        />
      </div>

      <Modal isOpen={action !== UNDEFINED_ACTION} title={modalTitle} onClose={onCloseForm}>
        <ComboForm
          action={action}
          currentId={currentId}
          onChangeCurrentId={(e) => setCurrentId(e)}
          onCancel={onCloseForm}
        />
      </Modal>
    </div>
  </>)
}
