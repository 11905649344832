import React, { useState, useEffect } from 'react';
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { decimalAdjust } from '../../Global';
import NumericInput from 'react-numeric-input';
import { BotonAddItem, BotonRemovePequeño } from '../Plantillas/Botones';
import ReactTable from "react-table";
import { notificarError, notificarMsg } from '../Almacenes/AlmacenNotify';


function ListaDetalles(props) {
  return <>
    <ReactTable
      data={props.detalles}
      disabled={false}
      columns={[
        {
          Header: "Producto",
          accessor: "Producto",
          minWidth: 215,
        },
        {
          Header: "Cantidad",
          accessor: "Cantidad",
          maxWidth: 140,
          Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>
        },
        {
          Header: "Precio de venta",
          accessor: "PrecioVenta",
          maxWidth: 140,
          Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>
        },
        {
          Header: "Descuento",
          accessor: "Descuento",
          maxWidth: 110,
          Cell: row => <div style={{textAlign: "right"}}>{row.value}</div>
        },
        {
          Header: "Precio c/ dscto.",
          accessor: "PrecioConDscto",
          maxWidth: 110,
          Cell: row => <div style={{textAlign: "right"}}>{decimalAdjust('floor', row.original.PrecioVenta - row.original.Descuento, -3)}</div>
        },
        {
          Header: "Acciones",
          accessor: "IdDetalleOC",
          Cell: (f) => {
            return (
              <div align='center'>
                <BotonRemovePequeño
                    title="Eliminar"
                    type='button'
                    className="ml-2"
                    onClick={
                        () => props.onDelete(f.original)
                    }
                />
              </div>
            )
          },
        },
      ]}

      defaultPageSize={20}
      showPagination={true}
      style={{
          height: "200px",
          width: "100%",
          fontSize: "13px"
      }}
      className="-striped -highlight"
    />
  </>
}


export default function(props) {
  const [nombre, setNombre] = useState("");
  const [product, setProduct] = useState(null);
  const [descuento, setDescuento] = useState(0.000000);
  const [precioVenta, setPrecioVenta] = useState(0.000000);
  const [cantidad, setCantidad] = useState(0.000000);
  const [codigoBarras, setCodigoBarraas] = useState("");
  const [detalles, setDetalles] = useState([]);

  useEffect(() => {
    if (!props.currentId) {
      return;
    }

    const load = async () => {
      try {
        const req = await fetch(`/api/combos/${props.currentId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Content': 'application/json',
          }
        });
  
        if (!req.ok) {
          throw new Error();
        }
  
        const combo = await req.json();

        setNombre(combo.Nombre);
        setCodigoBarraas(combo.CodigoBarras)

        let items = [];

        for (const det of combo.detalles) {
          const productos = await searchProduct(det.Producto);

          const p = productos.find(x => x.IdPresentacion == det.IdPresentacion);

          if (!p) {
            continue;
          }

          items.push({
            IdPresentacion: p.IdPresentacion,
            Producto: p.label,
            Descuento: det.Descuento,
            Cantidad: det.Cantidad,
            PrecioVenta: p.precio,
          })
        }

        setDetalles(items);
      } catch (e) {
        console.error(e)
        notificarError("No se puede obtener información en este momento. Intentelo en unos minutos.")
        props.onCancel();
      }
    }

    load();
    
  }, [props.currentId])

  const searchProduct = async (search) => {
    let products = []

    try {
      const req = await fetch(`/api/preVentas/?search=${search}&idTipoStock=1`);

      if (!req.ok) {
        throw new Error(await req.text())
      }

      const response = await req.json()

      products = response.productos.map((p) => {
        return {
          ...p,
          label: `${p.descripcion} ${p.unidadMedida}`,
          value: p.IdPresentacion
        }
      });
    } catch (e) {
      console.error(e)
    }

    return products;
  }

  const onChooseProduct = (p) => {
    setCantidad(1);
    setDescuento(0);
    setPrecioVenta(p.precio)
    setProduct(p);

    document.querySelector("#combo-form").querySelector("[name=descuento]").focus()
  }

  const onAgregarItem = () => {
    const alreadyExists = detalles.find(x => x.IdPresentacion == product.IdPresentacion);

    if (alreadyExists) {
      notificarError("El producto ya se encuentra registrado.")
      return;
    }

    setDetalles([...detalles, {
      IdPresentacion: product.IdPresentacion,
      Producto: product.label,
      Descuento: descuento,
      Cantidad: cantidad,
      PrecioVenta: product.precio,
    }])

    setCantidad(1);
    setDescuento(0);
    setPrecioVenta(0);
    setProduct(null);
  }

  const onCancel = () => {
    props.onCancel();
  }
  
  const onSubmit = async () => {
    if (detalles.length == 0) {
      notificarError("Se debe asignar al menos un producto.")
      return;
    }

    try {
      const url = !!props.currentId ? `/api/combos/${props.currentId}` : `/api/combos`

      const req = await fetch(url, {
        method: !!props.currentId ? "PUT" : "POST",
        body: JSON.stringify({
          Nombre: nombre,
          CodigoBarras: codigoBarras,
          Detalles: detalles.map((d) => ({
            IdPresentacion: d.IdPresentacion,
            Descuento: d.Descuento,
            Cantidad: d.Cantidad,
          }))
        }),
        headers: {
          'Content-Type': 'application/json',
          'Accept-Content': 'application/json',
        }
      });

      if (!req.ok) {
        throw new Error()
      }

      notificarMsg("Se guardaron los cambios correctamente.")

      props.onCancel();

    } catch (e) {
      notificarError("No se pudo registrar el combo. Inténtalo otra vez.")
      console.error(e);
    }
  }

  const onDelete = (detalle) => {
    const newDetalles = detalles.filter(x => x.IdPresentacion != detalle.IdPresentacion);
    setDetalles(newDetalles);
  }

  return <>
    <div id="combo-form" className="container">
      <div className="row">
        <div className="col-12 border-bottom">
          <div className="form-group">
            <label htmlFor="nombre">Nombre: </label>

            <input
              className="form-control"
              name="nombre"
              type="text"
              placeholder="Ingrese un nombre para el combo..."
              onChange={e => setNombre(e.target.value)}
              value={nombre}
              autoComplete="off"
            />
          </div>
        </div>

        <div className="col-12 mt-2">
          <div className="form-group">
            <label>Codigo barras:</label>
            <input
              className="form-control"
              name="codBarras"
              type="text"
              placeholder="Ingrese un codigo de barras para el combo..."
              onChange={e => setCodigoBarraas(e.target.value)}
              value={codigoBarras}
              autoComplete="off"
            />
          </div>
        </div>

        <div className="col-12 mt-2">
          <div className="form-group">
            <label>Buscar producto:</label>
            <AsyncSelect
              isClearable={true}
              onChange={(p) => onChooseProduct(p)}
              value={product}
              loadOptions={searchProduct}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <label htmlFor="descuento">Cantidad: </label>

            <NumericInput
              className="form-control"
              name="cantidad"
              min={0}
              max={10000}
              step={1}
              type="number"
              onChange={val => setCantidad(val)}
              value={decimalAdjust('floor', cantidad, -6)}
            />
          </div>
        </div>

        <div className="col-4">
          <div className="form-group">
            <label htmlFor="precio-venta">Precio de venta: </label>

            <NumericInput
              className="form-control"
              name="precio-venta"
              min={0}
              max={10000}
              step={1}
              type="number"
              value={decimalAdjust('floor', precioVenta, -6)}
              disabled
            />
          </div>
        </div>

        <div className="col-4">
          <div className="form-group">
            <label htmlFor="descuento">Descuento: </label>

            <NumericInput
              className="form-control"
              name="descuento"
              min={0}
              max={10000}
              step={1}
              type="number"
              onChange={val => setDescuento(val)}
              value={decimalAdjust('floor', descuento, -6)}
            />
          </div>
        </div>

        <div className="col-4">
          <div className="form-group">
            <label htmlFor="precio-venta-descuento">Subtotal: </label>

            <NumericInput
              className="form-control"
              name="precio-venta-descuento"
              type="number"
              value={decimalAdjust('floor', precioVenta - descuento, -3)}
              disabled
            />
          </div>
        </div>

        <div className="col-12 d-flex justify-content-end">
          <BotonAddItem
            title="Agregar item"
            type="button"
            onClick={onAgregarItem}
          />
        </div>

        <div className="col-12 mt-3">
          <ListaDetalles detalles={detalles} onDelete={onDelete}/>

          <br />

          <div className="w-100 d-flex justify-content-between">
            <span className="font-weight-bold">Cantidad de items: {detalles.length}</span>
            <span className="font-weight-bold">Total descuento: S/ {decimalAdjust('floor', detalles.reduce((acc, curr) => curr.Descuento * curr.Cantidad + acc, 0), -2)}</span>
            <span className="font-weight-bold">Total: S/ {decimalAdjust('floor', detalles.reduce((acc, curr) => (curr.PrecioVenta - curr.Descuento) * curr.Cantidad + acc, 0), -2)}</span>
          </div>
          
        </div>

        <div className="col-12 mt-3 d-flex justify-content-center">
          <button type="button" className="btn btn-secondary mr-2" onClick={onCancel}>Cancelar</button>
          <button type="button" className="btn btn-info" onClick={onSubmit}>Guardar cambios</button>
        </div>
      </div>
    </div>
  </>
}
